<div
    class="{{ environment.preproduction || environment.development || environment.local ? '' : 'fixed top-0' }} bg-gray-800 px-10 flex justify-between items-center py-1.5 z-30 w-full m-0">

    <div class="flex">
        <a routerLink="/production-orders">
            <div class="flex items-center pr-7 border-r border-gray-500 h-full">
                <img class="w-3 h-auto mr-2" src="assets/svg/devoltec-logo.svg" alt="Logo" loading="lazy" />
                <p class="text-[15px] font-semibold text-gray-50">MES</p>
            </div>
        </a>

        &nbsp;&nbsp;&nbsp;&nbsp;

        <ul nz-menu nzMode="horizontal" class="custom-menu">
            <li nz-submenu nzTitle="Ordenes" [routerLink]="['/production-orders']">
                <ul hidden>
                    <li nz-menu-item [nzSelected]="currentRoute === '/production-orders'"></li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Pantallas" [routerLink]="['/screens']">
                <ul hidden>
                    <li nz-menu-item [nzSelected]="currentRoute === '/screens'"></li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Máquinas">
                <ul>
                    <li nz-menu-item [routerLink]="['/machines']" [nzSelected]="currentRoute === '/machines'">Máquinas</li>
                    <li nz-menu-item [routerLink]="['/machine-values']" [nzSelected]="currentRoute === '/machine-values'">Valores de máquina</li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Artículos">
                <ul>
                    <li nz-menu-item [routerLink]="['/articles']" [nzSelected]="currentRoute === '/articles'">Artículos</li>
                    <li nz-menu-item [routerLink]="['/article-types']" [nzSelected]="currentRoute === '/article-types'">Tipos de artículo</li>
                    <li nz-menu-item [routerLink]="['/article-family']" [nzSelected]="currentRoute === '/article-family'">Familias de artículo</li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Taras">
                <ul>
                    <li nz-menu-item [routerLink]="['/scrap-causes']" [nzSelected]="currentRoute === '/scrap-causes'">Causas de tara</li>
                    <li nz-menu-item [routerLink]="['/scrap-reasons']" [nzSelected]="currentRoute === '/scrap-reasons'">Razones de tara</li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Más">
                <ul>
                    <li nz-menu-item [routerLink]="['/users']" [nzSelected]="currentRoute === '/users'">Usuarios</li>
                    <li nz-menu-item [routerLink]="['/clients']" [nzSelected]="currentRoute === '/clients'">Clientes</li>
                    <li nz-menu-item [routerLink]="['/routes']" [nzSelected]="currentRoute === '/routes'">Rutas</li>
                    <li nz-menu-item [routerLink]="['/productionprocesses']" [nzSelected]="currentRoute === '/productionprocesses'">Procesos de producción</li>
                    <li nz-menu-item [routerLink]="['/statuses']" [nzSelected]="currentRoute === '/statuses'">Estados</li>
                </ul>
            </li>

        </ul>
    </div>

    <div class="flex items-center">
        <div class="pr-4 border-r border-gray-500">
            <p class="text-white font-medium">v.{{ version }}</p>
        </div>
        @if (loggedScreen) {
        <div class="flex items-center text-white font-normal text-[13px] ml-4 pr-4 border-r border-gray-500">
            <span class="font-semibold">Pantalla:</span>&nbsp;&nbsp;{{
            loggedScreen.name
            }}
        </div>
        }

        <div class="ml-4 dropdown dropdown-end">
            <div class="relative inline-block text-left">
                <app-dropdown-user></app-dropdown-user>
            </div>
        </div>
    </div>
</div>
