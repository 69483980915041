import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService, NzModalTitleDirective, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { ScrapDto } from 'src/app/shared/dto/scrap.dto';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzThMeasureDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-modal-watch-scraps',
    templateUrl: './modal-watch-scraps.component.html',
    styleUrls: ['./modal-watch-scraps.component.scss'],
    standalone: true,
    imports: [
    NzModalTitleDirective,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NzModalFooterDirective,
    DatePipe
],
})
export class ModalWatchScrapsComponent implements OnInit {
    isLoading: boolean = true;
    scraps: ScrapDto[] = [];
    article!: ArticleDto;
    operatorName: string = '';
    processId: number = 0;
    processDescription: string = '';

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private _modalService: NzModalService,
    ) {
        this.scraps = (data?.data?.scraps as ScrapDto[]) ?? [];
        this.article = (data?.data?.article.article as ArticleDto) ?? {};
        this.operatorName = data?.data?.operatorName as string;
        this.processId = data?.data?.processId as number;
        this.processDescription = data?.data?.processDescription as string;

        this.isLoading = false;
    }

    async ngOnInit() {}

    handleOk(): void {
        this._modalService.closeAll();
    }
}
