<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Estados" nzSubtitle="Estados en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="openCreateStatusDrawer()">
                    Añadir estado
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #customersTable [nzData]="statuses" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Siguiente estado</th>
                <th>¿Estado de inicio?</th>
                <th>¿Estado de final?</th>
                <th>¿Estado de pausa?</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of customersTable.data; track data) {
            <tr>
                <td><span [ngClass]="data.cssClassName">{{ data.name }}</span></td>
                <td>{{getNextStatusName(data.nextStatus)}}</td>
                <td>{{ data.isStartingStatus ? 'Sí' : 'No' }}</td>
                <td>{{ data.isEndingStatus ? 'Sí' : 'No' }}</td>
                <td>{{ data.isPauseStatus ? 'Sí' : 'No' }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeStatus(data.id)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editStatus(data.id)"
                            title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteStatus(data.id)"
                            title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="statusSelected.name ? statusSelected.name : ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="name" />

            <label>Abreviatura:</label>
            <input nz-input [value]="statusSelected.abbreviation ? statusSelected.abbreviation : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="abbreviation" />

            <label nz-checkbox [(ngModel)]="isStartingStatus" [nzDisabled]="fieldsReadOnly" name="isStartingStatus">¿Es
                un estado de comienzo?</label>
            <br>

            <label nz-checkbox [(ngModel)]="isEndingStatus" [nzDisabled]="fieldsReadOnly" name="isEndingStatus">¿Es un
                estado de final?</label>
            <br>

            <label nz-checkbox [(ngModel)]="isPauseStatus" [nzDisabled]="fieldsReadOnly" name="isPauseStatus">¿Es un
                estado de pausa?</label>
            <br>

            <label>Siguiente estado:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona" [(ngModel)]="nextStatus" name="nextStatusSelector"
                class="searchSelectors" [nzDisabled]="fieldsReadOnly || isEndingStatus || isPauseStatus">
                @for (p of statuses; track p.name) {
                <nz-option [nzLabel]="p.name" [nzValue]="p"></nz-option>
                }
            </nz-select>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            } @else {
            <!-- Metadata goes here -->
            }
        </form>
    </ng-container>
</nz-drawer>