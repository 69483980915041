import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective } from 'ng-zorro-antd/table';
import { ArticleBatchesService } from 'src/app/private/services/article-batches.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { RawMaterialDto } from 'src/app/shared/dto/rawMaterial.dto';
import { ModalArticleQuantityComponent } from '../modal-article-quantity/modal-article-quantity.component';


interface PlainRawMaterial {
    id?: number;
    batchNumber?: string;
    name: string;
    articleId?: number;
    articleBatchId?: number;
    measurementUnit?: string;
    quantity: number;
}

@Component({
    selector: 'app-modal-raw-material',
    templateUrl: './modal-raw-material.component.html',
    styleUrls: ['./modal-raw-material.component.scss'],
    standalone: true,
    imports: [
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NzCheckboxComponent,
        ReactiveFormsModule,
        FormsModule,
        NzModalFooterDirective,
        NzMessageModule,
    ],
})
export class ModalRawMaterialComponent{
    @Output() emitService = new EventEmitter();

    isLoading: boolean = true;
    rawMaterials: PlainRawMaterial[] = [];
    selectedBatches: number[] = [];
    processOrderIsCreated: boolean;
    private _processOrderId!: number;

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private articleBatchesService: ArticleBatchesService,
        private modalService: NzModalService,
        private toast: NzMessageService,
    ) {
        this._processOrderId = Number(data.data.orderProcessId);
        this.processOrderIsCreated = this._processOrderId > -1;

        if (this.processOrderIsCreated) {
            this.selectedBatches = this.rawMaterials.map(
                (material) => material.id as number,
            );
        }

        if (data.data.rawMaterials) {
            data.data.rawMaterials.forEach((rawMaterial: RawMaterialDto) => {
                this.rawMaterials.push({
                    articleId: rawMaterial.article?.id ?? -1,
                    articleBatchId: rawMaterial.articleBatch?.id ?? -1,
                    batchNumber: rawMaterial.articleBatch?.batchNumber ?? '',
                    name: rawMaterial.article?.name ?? rawMaterial.articleBatch!.article!.name,
                    measurementUnit: rawMaterial.article?.measurementUnit.abbreviation ?? rawMaterial.articleBatch!.article!.measurementUnit.abbreviation,
                    quantity: rawMaterial.quantity
                });
            });

            this.isLoading = false;
        }
    }

    addRawMaterial(articleBatchId: number) {
        if (
            this.selectedBatches.find((batchId) => batchId === articleBatchId)
        ) {
            delete this.selectedBatches[
                this.selectedBatches.indexOf(articleBatchId)
            ];

            return;
        }

        const foundRawMaterial = this.rawMaterials.find(
            (elem) => elem.id === articleBatchId,
        );

        if (!foundRawMaterial) {
            this.toast.error(
                `No se ha encontrado una materia prima con el id ${articleBatchId}`,
            );

            return;
        }

        this.selectedBatches.push(articleBatchId);

        const modal = this.modalService.create({
            nzTitle: `Selección de cantidad`,
            nzContent: ModalArticleQuantityComponent,
            nzStyle: { width: '90%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    quantity: foundRawMaterial.quantity,
                    showAddButtons: false,
                },
            },
        });

        modal.componentInstance?.emitService.subscribe((value) => {
            modal.close();

            const quantity: number = value as number;

            console.log(quantity);
            if (quantity === -1) {
                return;
            }

            const arrIndex: number = this.rawMaterials.findIndex(
                (elem) => elem.id === articleBatchId,
            );

            if (arrIndex > -1) {
                this.rawMaterials[arrIndex].quantity =
                    this.rawMaterials[arrIndex].quantity - quantity;
            }

            /*
                ToDo:
                    - Update all selected article batches quantity
                    -
            */
        });
    }

    handleOk() {
        try {
            if (this.selectedBatches.length === 0) {
                this.toast.warning(
                    'Has de seleccionar al menos 1 materia prima.',
                );

                return;
            }

            const rawMaterialsToUpdate: PlainRawMaterial[] =
                this.rawMaterials.filter((material) =>
                    this.selectedBatches.includes(material.id as number),
                ) as PlainRawMaterial[];

            this.articleBatchesService.saveSelectedRawMaterials(
                rawMaterialsToUpdate as ArticleBatchDto[],
                this._processOrderId,
            );

            this.emitService.next(rawMaterialsToUpdate);

            this.modalService.closeAll();
        } catch (e: any) {
            if (e instanceof Error) {
                this.toast.error(e.message);
            } else {
                this.toast.error(String(e));
            }
        }
    }

    handleCancel() {
        this.modalService.closeAll();
    }
}
