import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { isValidIpAddress } from 'src/app/shared/utils/utils';
import { MachineService } from '../../services/machine.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-machines',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
    ],
    templateUrl: './machines.component.html',
    styleUrl: './machines.component.scss'
})
export class MachinesComponent implements OnInit, OnDestroy {

    readonly machineService = inject(MachineService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    machines: MachineDto[] = [];
    drawerVisible: boolean = false;
    fieldsReadOnly: boolean = true;
    drawerTitle: string = 'Placeholder';
    machineSelected: MachineDto = new MachineDto();
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
    isNewMachine: boolean = false;
    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.machineService.setupWebSocket();

        this.machineService.machines$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machines: MachineDto[]) => {
                    this.machines = machines;
                },
                error: () => {
                    this.message.error('Error cargando máquinas');
                }
            });

        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.machineService.disconnectWebsocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createMachine() {
        this.fieldsReadOnly = false;
        this.machineSelected = { id: 0, name: '', ip: '', queueName: '', createdAt: new Date(), updatedAt: new Date(), createdBy: 0, updatedBy: 0 };
        this.drawerTitle = 'Creando nueva máquina';
        this.isNewMachine = true;
        this.openDrawer();
    }

    seeMachine(id: number) {
        this.fieldsReadOnly = true;
        this.machineSelected = this.machines.find((p) => p.id === id) as MachineDto;
        this.drawerTitle = 'Viendo máquina ' + this.machineSelected?.name;
        this.createdAt = this.machineSelected.createdAt
            ? new Date(this.machineSelected.createdAt)
            : new Date();
        this.updatedAt = this.machineSelected.updatedAt
            ? new Date(this.machineSelected.updatedAt)
            : new Date();

        this.setData();
        this.openDrawer();
    }

    editMachine(id: number) {
        this.fieldsReadOnly = false;
        this.isNewMachine = false;
        this.machineSelected = this.machines.find((p) => p.id === id) as MachineDto;
        this.drawerTitle = 'Editando máquina ' + this.machineSelected?.name;

        this.setData();
        this.openDrawer();
    }

    setData() {
        // This does nothing, it's just for consistency
    }

    cancelEditing() {
        this.closeDrawer();
    }

    async deleteMachine(id: number) {
        try {
            if (window.confirm('¿Estás seguro de que quieres eliminar esta máquina? Esta acción no se puede deshacer.')) {
                const machine: MachineDto = await this.machineService.deleteMachine(id);

                if (!machine) {
                    this.message.error(`No se ha encontrado una máquina con id ${id}`);
                }

                const index = this.machines.findIndex((p) => p.id === id);

                if (index < 0) {
                    this.message.create('error', `No se ha podido eliminar la máquina ${machine.name} con id ${id}, máquina no encontrada`);
                }

                this.machines.splice(index, 1);
                const data = [...this.machines];
                this.machines = data;

                this.message.create('success', 'Máquina eliminada correctamente');
            }
        } catch (e: any) {
            this.message.error(e.message);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    async saveEditing() {
        try {
            const customMachineDto: MachineDto = {
                id: this.machineSelected.id,
                name: (<HTMLInputElement>document.getElementById('name')).value
                    ? ((<HTMLInputElement>document.getElementById('name'))
                        .value as string)
                    : '',
                createdBy: this.isNewMachine ? this.currentUser.id : this.machineSelected.createdBy,
                ip: (<HTMLInputElement>document.getElementById('ip')).value
                    ? ((<HTMLInputElement>document.getElementById('ip'))
                        .value as string)
                    : '',
                queueName: (<HTMLInputElement>document.getElementById('queueName')).value
                    ? ((<HTMLInputElement>document.getElementById('queueName'))
                        .value as string)
                    : '',
                updatedBy: this.currentUser.id
            };

            if (!this.validForm(customMachineDto)) {
                return;
            }

            if (this.isNewMachine) {
                const createdMachine: MachineDto = await this.machineService.create(customMachineDto);

                if (createdMachine == undefined) {
                    throw new Error('Error creating machine');
                }

                this.machines.push(createdMachine);
                const data = [...this.machines];
                this.machines = data;

                this.message.create('success', 'Máquina creada correctamente');
                this.closeDrawer();
            } else {
                const updatedMachine: MachineDto = await this.machineService.updateMachine(customMachineDto);

                if (updatedMachine == undefined) {
                    throw new Error('Error updating machines');
                }

                const index = this.machines.findIndex((r) => r.id === updatedMachine.id);

                if (index < 0) {
                    this.message.create('error', `No se ha podido actualizar la máquina ${customMachineDto.name} con id ${customMachineDto.id}, máquina no encontrada`);
                    throw new Error('Machine not found');
                }

                this.machines[index] = updatedMachine;
                const data = [...this.machines];
                this.machines = data;

                this.message.create('success', 'Máquina actualizada correctamente',);
                this.closeDrawer();
            }
        } catch (e: any) {
            this.message.error(`${e.message}`);
        }
    }

    validForm(machine: MachineDto): boolean {
        if (!machine.name || machine.name === '') {
            this.message.create(
                'error',
                'El nombre del proceso de producción es obligatorio'
            );

            return false;
        }

        if (!machine.ip || machine.ip === '') {
            this.message.create(
                'error',
                'La dirección IP de la máquina es obligatoria'
            );

            return false;
        }

        if (!isValidIpAddress(machine.ip)) {
            this.message.create(
                'error',
                'La dirección IP de la máquina no es válida'
            );

            return false;
        }

        return true;
    }
}
