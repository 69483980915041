import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ScrapCauseDto } from 'src/app/shared/dto/scrap-cause.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ScrapCauseService } from '../../services/scrap-cause.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-scrap-causes',
    standalone: true,
    imports: [
        NzDividerModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDrawerModule,
        FormsModule
    ],
    templateUrl: './scrap-causes.component.html',
    styleUrl: './scrap-causes.component.scss'
})
export class ScrapCausesComponent implements OnInit, OnDestroy {
    readonly causesService = inject(ScrapCauseService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    drawerVisible: boolean = false;
    drawerTitle: string = 'Placeholder';
    fieldsReadOnly: boolean = true;
    causeSelected: ScrapCauseDto = new ScrapCauseDto();
    scrapCauses: ScrapCauseDto[] = [];
    currentUser: UserDto = new UserDto();
    isNewCause: boolean = false;
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;
        await this.causesService.setupWebSocket();
        this.causesService.scrapCauses$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (causes: ScrapCauseDto[]) => {
                    this.scrapCauses = causes;
                    this.loadingTable = false;
                },
                error: () => {
                    this.message.error('Error cargando causas de tara');
                }
            });
    }

    ngOnDestroy(): void {
        this.causesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createCause() {
        this.fieldsReadOnly = false;
        this.causeSelected = { id: 0, text: '' };
        this.drawerTitle = 'Creando nueva causa de tara';
        this.isNewCause = true;
        this.openDrawer();
    }

    seeCause(id: number) {
        this.fieldsReadOnly = true;
        this.causeSelected = this.scrapCauses.find(
            (p) => p.id === id,
        ) as ScrapCauseDto;
        this.drawerTitle = 'Viendo causa ' + this.causeSelected?.text;
        this.setData();
        this.openDrawer();
    }

    editCause(id: number) {
        this.fieldsReadOnly = false;
        this.isNewCause = false;
        this.causeSelected = this.scrapCauses.find(
            (p) => p.id === id,
        ) as ScrapCauseDto;
        this.drawerTitle = 'Editando causa ' + this.causeSelected?.text;
        this.setData();
        this.openDrawer();
    }

    setData() {
        // This does nothing, it's just for consistency
    }

    cancelEditing() {
        this.closeDrawer();
    }

    deleteCause(id: number) {
        if (window.confirm('¿Estás seguro de que quieres eliminar esta causa de tara? Esta acción no se puede deshacer.')) {
            this.causesService.delete(id).subscribe({
                next: (cause: ScrapCauseDto) => {
                    try {
                        if (cause == undefined) {
                            this.message.create('error', `No se ha encontrado la causa de tara ${id}`);

                            return;
                        }

                        const index = this.scrapCauses.findIndex((p) => p.id === id);

                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar la causa ${cause.text} con id ${id}, causa de tara no encontrada`);

                            return;
                        }

                        this.scrapCauses.splice(index, 1);
                        const data = [...this.scrapCauses];
                        this.scrapCauses = data;
                        this.message.create('success', 'Causa de tara eliminado correctamente',);
                    } catch (error: any) {
                        this.message.create('error', `Error eliminando causa de tara. Error: ${error.message}`);
                    }
                },
                error: (error) => {
                    this.message.create('error', `Error eliminando causa de tara, error de servidor. Error: ${error.message}`);
                }
            });
        } else {
            console.log('Cancelando eliminación de causa de tara ' + id,);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customCauseDto: ScrapCauseDto = {
            id: this.causeSelected.id,
            text: (<HTMLInputElement>document.getElementById('name')).value
                ? ((<HTMLInputElement>document.getElementById('name'))
                    .value as string)
                : '',
        };

        if (!this.validForm(customCauseDto)) {
            return;
        }

        if (this.isNewCause) {
            this.causesService.save(customCauseDto).subscribe({
                next: (cause: ScrapCauseDto) => {
                    try {
                        if (cause == undefined) {
                            this.message.create('error', `Error creando causa de tara`);

                            return;
                        }

                        this.scrapCauses.push(cause);
                        const data = [...this.scrapCauses];
                        this.scrapCauses = data;
                        this.message.create('success', 'Causa de tara creada correctamente');
                        this.closeDrawer();
                    } catch (error: any) {
                        this.message.create('error', `Error creando causa de tara. Error: ${error.message}`);
                    }
                },
                error: (error: any) => {
                    this.message.create('error', `Error creando causa de tara, error de servidor. Error: ${error.message}`);
                }
            });
        } else {
            this.causesService.update(customCauseDto.id, customCauseDto).subscribe({
                next: (cause: ScrapCauseDto) => {
                    try {
                        if (cause == undefined) {
                            this.message.create('error', 'Error creando causas de tara');

                            return;
                        }

                        const index = this.scrapCauses.findIndex(
                            (r) => r.id === cause.id
                        );

                        if (index < 0) {
                            this.message.create('error', `No se ha podido actualizar la causa ${customCauseDto.text} con id ${customCauseDto.id}, causa de tara no encontrada`);

                            return;
                        }

                        this.scrapCauses[index] = cause;
                        const data = [...this.scrapCauses];
                        this.scrapCauses = data;

                        this.message.create('success', 'Causa de tara actualizada correctamente');

                        this.closeDrawer();
                    } catch (error: any) {
                        this.message.create('error', `Error actualizando causa de tara. Error: ${error.message}`);
                    }
                },
                error: (error: any) => {
                    this.message.create('error', `Error actualizando causa de tara, error de servidor. Error: ${error.message}`);
                }
            });
        }
    }

    validForm(cause: ScrapCauseDto): boolean {
        if (!cause.text) {
            this.message.create(
                'error',
                'El texto de la causa de tara es obligatorio',
            );
            return false;
        }
        return true;
    }
}
