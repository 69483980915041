export enum RoomNames {
    DEFAULT_ROOM = 'default-room',
    PRODUCTION_ORDERS_ROOM = 'production-orders-room',
    CONFIGURATIONS_ROOM = 'configurations-room',
    ARTICLE_BATCHES_ROOM = 'article-batches-room',
    ARTICLE_FAMILIES_ROOM = 'article-families-room',
    ARTICLE_TYPES_ROOM = 'article-types-room',
    ARTICLES_ROOM = 'articles-room',
    ASSESS_ROOM = 'assess-room',
    CUSTOM_FIELDS_ROOM = 'custom-fields-room',
    CUSTOMERS_ROOM = 'customers-room',
    MACHINE_MEASUREMENTS_ROOM = 'machine-measurements-room',
    MACHINE_MEASUREMENTS_VALUES_ROOM = 'machine-measurements-values-room',
    MACHINES_ROOM = 'machines-room',
    MEASUREMENT_UNITS_ROOM = 'measurement-units-room',
    PAUSES_ROOM = 'pauses-room',
    PRODUCTION_ORDER_PROCESSES_ROOM = 'production-order-processes-room',
    PRODUCTION_PROCESSES_ROOM = 'production-processes-room',
    PRODUCTION_ROUTES_ROOM = 'production-routes-room',
    SCRAP_CAUSES_ROOM = 'scrap-causes-room',
    SCRAP_REASONS_ROOM = 'scrap-reasons-room',
    SCREENS_ROOM = 'screens-room',
    SENSORS_ROOM = 'sensors-room',
    STATUSES_ROOM = 'statuses-room',
    USERS_ROOM = 'users-room',
}

export enum Client {
    DEFAULT_CLIENT = 'default-client',
}

export function formatClientRoom(client: Client, room: RoomNames): string {
    return `${client}|${room}`;
}
