@if (
  (environment.preproduction || environment.development || environment.local) &&
  !showNoInternetScreen
  ) {
  <div
    >
    <app-pre-production-banner></app-pre-production-banner>
  </div>
}

@if (showNoInternetScreen) {
  <app-no-internet></app-no-internet>
} @else {
  <router-outlet></router-outlet>
}


