import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleBatchResponseDto } from 'src/app/shared/dto/pagination/article-batch-response.dto';

import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ArticleBatchesService extends BaseService {
    private articleBatchesSubject = new BehaviorSubject<ArticleBatchDto[]>([]);
    public articleBatches$ = this.articleBatchesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'articles');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.articleBatchesSubject.next(((await this.findAll()).articleBatches));
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLE_BATCHES_ROOM));
            this.socketService.getNotifications(Messages.ARTICLE_BATCHES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.articleBatchesSubject.next(response.articleBatches);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Lotes de artículos. Error: ${e.message}`);

        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLE_BATCHES_ROOM));
    }

    async findAll(): Promise<ArticleBatchResponseDto> {
        try {
            const request = this.httpClient.get<ArticleBatchResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun lote de artículos. Error: ${e.message}`);
        }
    }

    // TODO: REMOVE? findAll is doing the same
    async getArticleBatches(): Promise<ArticleBatchDto[]> {
        try {
            const articleBatches = this.httpClient.get<ArticleBatchDto[]>(
                `${this.url}/getAllBatches`,
            );
            return await lastValueFrom(articleBatches);
        } catch (e: any) {
            throw new Error(`No se ha podido obtener los lotes: Error: ${e.message}`);
        }
    }

    async createArticleBatch(articleBatch: ArticleBatchDto, orderProcessId: number): Promise<ArticleBatchDto> {
        try {
            if (orderProcessId < 0 || !orderProcessId) {
                throw new Error('El id del proceso de la orden es requerido para crear y asignar el lote');
            }

            const request = this.httpClient.post<any>(`${this.url}/createArticleBatch/${orderProcessId}`, articleBatch);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido crear el lote y asignarlo al proceso ${orderProcessId}: Error: ${e.message}`);
        }
    }

    async saveSelectedRawMaterials(
        selectedArticleBatches: ArticleBatchDto[],
        orderProcessId: number
    ): Promise<void> {
        try {
            if (selectedArticleBatches.length === 0 || orderProcessId < 0) {
                throw new Error('Los parámetros para guardar las materias primas no pueden estar vacíos.');
            }

            await lastValueFrom(
                this.httpClient.post<any>(`${this.url}/saveRawMaterials`, {
                    articleBatches: selectedArticleBatches,
                    orderProcessId: orderProcessId
                })
            );
        } catch (e: any) {
            throw new Error(`No se ha podido guardar las materias primas para el proceso ${orderProcessId}: Error: ${e.message}`);
        }
    }

    async updateQuantityToProduce(
        orderId: number,
        processId: number,
        selectedArticleId: number,
        quantityToProduce: number
    ): Promise<void> {
        try {
            if (
                orderId < 0 ||
                processId < 0 ||
                selectedArticleId < 0 ||
                quantityToProduce < 0
            ) {
                throw new Error('Los parámetros para actualizar la cantidad del artículo no pueden estar vacío.');
            }

            await lastValueFrom(
                this.httpClient.post<any>(`${this.url}/updateQuantityToProduce`, {
                    articleId: selectedArticleId,
                    orderId: orderId,
                    processId: processId,
                    quantityToProduce: quantityToProduce
                }
                )
            );
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la cantidad a producir para el proceso ${processId} de la orden ${orderId}: Error: ${e.message}`);
        }
    }

    /**
     * Updates the quantity of an article batch
     * @param articleBatchId The id of the article batch
     * @param quantity The new quantity
     * @returns The updated article batch
     */
    async updateQuantity(articleBatchId: number, quantity: number): Promise<ArticleBatchDto> {
        try {
            if (quantity < 0 || articleBatchId <= 0) {
                throw new Error('La cantidad no puede ser inferior a 0 y el id del lote ha de ser mayor de 0');
            }

            const request = this.httpClient.patch<ArticleBatchDto>(`${this.url}/updateArticleBatchQuantity/${articleBatchId}`, { quantity: quantity });

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la cantidad del lote ${articleBatchId}: Error: ${e.message}`);
        }
    }

    /**
     * Updates the quantities of a list of article batches
     * @param articleBatches An array of objects with the id of the article batch and the quantity to update
     * @returns The updated article batches
     */
    async updateQuantities(articleBatches: { id: number, quantity: number }[]): Promise<ArticleBatchDto[]> {
        try {
            const updatedBatches: ArticleBatchDto[] = await lastValueFrom(this.httpClient.patch<ArticleBatchDto[]>(`${this.url}/updateArticleBatchesQuantities`, articleBatches));

            if (!updatedBatches) {
                throw new Error('Error updating batches quantities');
            }

            return updatedBatches;
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la cantidad para los lotes. Error: ${e.message}`);
        }
    }

    /**
     * Get the batches of an article
     * @param articleId The id of the article
     * @returns Array of batches of the article
     */
    async getBatchesByArticleId(articleId: number): Promise<ArticleBatchDto[]> {
        try {
            if (articleId <= 0) {
                throw new Error('El id del articulo ha de ser mayor de 0');
            }

            const query = this.httpClient.get<ArticleBatchDto[]>(`${this.url}/getBatchesByArticleId/${articleId}`);

            return await lastValueFrom(query);
        } catch (e: any) {
            throw new Error(`No se han podido obtener los lotes para el artículo ${articleId}. Error: ${e.message}`);
        }
    }
}
