import { Component, inject, input, model, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzTransferComponent, TransferDirection, TransferItem } from 'ng-zorro-antd/transfer';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { MachineService } from 'src/app/private/services/machine.service';
import { ScreenService } from 'src/app/public/services/screen.service';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { DirectionsEnum } from 'src/app/shared/enums/directions.enum';

@Component({
    selector: 'app-screens-transfer',
    templateUrl: './screens-transfer.component.html',
    styleUrl: './screens-transfer.component.scss',
    standalone: true,
    imports: [
        NzTransferComponent,
        NzMessageModule,
    ],
})
export class ScreensTransferComponent implements OnInit, OnDestroy {
    readonly machinesService = inject(MachineService);
    readonly screenService = inject(ScreenService);
    readonly toast = inject(NzMessageService);

    saveEvent = input.required<Observable<void>>();
    screen = model.required<ScreenDto>();
    machinesTransferItems: TransferItem[] = [];
    availableMachines: MachineDto[] = [];
    disabled = false;
    saveSubs!: Subscription;

    constructor() {}

    async ngOnInit(): Promise<void> {
        if (this.screen() === undefined) {
            return;
        }
        this.screen.set(await this.getScreenById(this.screen()['id']));
        this.availableMachines = (await this.machinesService.findAll()).machines;
        this.availableMachines = this.availableMachines.filter((machine) => {
            return !this.screen().machines?.some((m) => m.machineId === machine.id);
        });
        this.initComponent();

        this.saveSubs = this.saveEvent().subscribe(() => this.save());
    }

    ngOnDestroy() {
        if (this.saveSubs !== undefined) {
            this.saveSubs.unsubscribe();
        }
    }

    /** left: disponibles, right: actuales */
    initComponent(): void {
        const transferItems: TransferItem[] = [];
        for (let i = 0; i < this.availableMachines.length; i++) {
            transferItems.push({
                key: this.availableMachines[i].id,
                title: this.availableMachines[i].name,
                description: this.availableMachines[i].ip,
                direction: DirectionsEnum.LEFT as TransferDirection,
            });
        }
        this.screen().machines!.forEach((element) => {
            console.log('¿Máquinas?', element);
            transferItems.push({
                key: element.machineId,
                title: element.machines.name,
                description: element.machines.ip,
                direction: DirectionsEnum.RIGHT as TransferDirection,
            });
        });
        this.machinesTransferItems = transferItems;
    }

    async getScreenById(screenId: number) {
        const screen$ = (await lastValueFrom(this.screenService.getById(screenId)));
        return screen$;
    }

    async save() {
        try {
            const assignMachines: {screenId: number, machineId: number}[] = [];

            this.machinesTransferItems.forEach((element) => {
                if (element.direction === DirectionsEnum.RIGHT as TransferDirection) {
                    assignMachines.push({
                        screenId: this.screen().id,
                        machineId: element['key'],
                    });
                }
            });

            await this.screenService.assignMachines('create', this.screen().id, assignMachines);
            
            this.toast.create('success', 'Máquinas asignadas correctamente');
        } catch (e: any) {
            this.toast.create('error', `No se ha podido guardar correctamente. Error: ${e.message}`);
        }
    }
}
