import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProfileDto } from 'src/app/shared/dto/profile.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private usersSubject = new BehaviorSubject<UserDto[]>([]);
    public users$ = this.usersSubject.asObservable();

    private readonly uls = inject(UserLoggedService);
    private readonly http = inject(HttpClient);
    private readonly socketService = inject(SocketService);

    async setupWebSocket(): Promise<void> {
        try {
            this.usersSubject.next((await this.getUsers()));
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.USERS_ROOM));
            this.socketService.getNotifications(Messages.USERS_CHANGED).subscribe(async () => {
                const response = await this.getUsers();
                this.usersSubject.next(response);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Usuarios. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.USERS_ROOM));
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        const userLogged: UserDto = this.uls.userLogged;

        const request = this.http.post<any>(
            environment.apiUrl + 'users/updatePassword/',
            {
                id: userLogged.id,
                oldPassword: oldPassword,
                newPassword: newPassword
            }
        );

        const updatedUser: UserDto = await lastValueFrom(request);

        return updatedUser !== null && updatedUser !== undefined;
    }

    async getUsers(): Promise<UserDto[]> {
        try {
            const request = this.http.get<UserDto[]>(environment.apiUrl + 'users');

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido obtener los usuarios. Error: ${e.message}`);
        }
    }

    async createUser(user: UserDto): Promise<UserDto> {
        try {
            if (user === null || user === undefined) {
                throw new Error('El usuario no es válido');
            }

            const request = this.http.post<UserDto>(environment.apiUrl + 'users', user);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido obtener los usuarios. Error: ${e.message}`);
        }
    }

    async updateUser(user: UserDto): Promise<UserDto> {
        try {
            if (user === null || user === undefined) {
                throw new Error('El usuario no es válido');
            }

            const request = this.http.patch<UserDto>(environment.apiUrl + 'users/' + user.id, user);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar el usuario. Error: ${e.message}`);
        }
    }

    async deleteUser(id: number): Promise<UserDto> {
        try {
            if (id <= 0) {
                throw new Error('El id del usuario ha de ser mayor de 0');
            }

            const request = this.http.delete<UserDto>(`${environment.apiUrl}users/${id}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar el usuario. Error: ${e.message}`);
        }
    }

    async getProfiles(): Promise<ProfileDto[]> {
        try {
            const request = this.http.get<ProfileDto[]>(environment.apiUrl + 'profiles');

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido obtener los perfiles. Error: ${e.message}`);
        }
    }
}
