<div class="mx-10 my-6">
  <div class="m-8">
    <div class="items-center">
      <div
        class="flex items-center justify-center w-full h-full mt-14 mb-10"
        >
        <button
          (click)="add(100, '-')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px]"
          >
          -100
        </button>
        <button
          (click)="add(50, '-')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px]"
          >
          -50
        </button>
        <button
          (click)="add(20, '-')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px]"
          >
          -20
        </button>
        <button
          (click)="add(10, '-')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px]"
          >
          -10
        </button>
        <button
          (click)="add(1, '-')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px]"
          >
          -1
        </button>
        <p class="text-9xl font-semibold mr-14 ml-14">
          {{ quantity }}
        </p>
        <button
          (click)="add(1, '+')"
          class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px] tracking-tight"
          >
          +1
        </button>
        @if (showAddButtons) {
          <div>
            <button
              (click)="add(10, '+')"
              class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px] tracking-tight"
              >
              +10
            </button>
            <button
              (click)="add(20, '+')"
              class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px] tracking-tight"
              >
              +20
            </button>
            <button
              (click)="add(50, '+')"
              class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px] tracking-tight"
              >
              +50
            </button>
            <button
              (click)="add(100, '+')"
              class="text-4xl text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[100px] w-[100px] h-[70px] tracking-tight"
              >
              +100
            </button>
          </div>
        }
      </div>

      <div class="flex items-center justify-center w-full h-full">
        <button
          (click)="handleOk()"
          class="flex justify-center mr-6 items-center text-4xl text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[120px] max-w-[120px] w-[120px] h-[120px] uppercase tracking-tight"
          >
          <svg
            class="w-2/3 h-2/3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </button>
        <button
          (click)="handleCancel()"
          class="flex justify-center items-center text-4xl text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[120px] max-w-[120px] w-[120px] h-[120px] uppercase tracking-tight"
          >
          <svg
            class="w-2/3 h-2/3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
