import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleFamilyDto } from 'src/app/shared/dto/article-family.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ArticleFamilyService } from '../../services/article-family.service';
import * as _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-article-families',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzTableModule,
        NzSelectModule
    ],
    templateUrl: './article-families.component.html',
    styleUrl: './article-families.component.scss'
})
export class ArticleFamiliesComponent implements OnInit, OnDestroy {
    readonly userLoggedService = inject(UserLoggedService);
    readonly familiesService = inject(ArticleFamilyService);
    readonly message = inject(NzMessageService);

    drawerVisible: boolean = false;
    drawerTitle: string = 'Placeholder';
    fieldsReadOnly: boolean = true;
    isNewFamily: boolean = false;
    currentUser: UserDto = new UserDto();
    familySelected: ArticleFamilyDto = new ArticleFamilyDto();
    subFamiliesSelected: ArticleFamilyDto[] = [];
    families: ArticleFamilyDto[] = [];
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.familiesService.setupWebSocket();
        this.familiesService.articleFamilies$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (families: ArticleFamilyDto[]) => {
                    this.families = families;
                },
                error: () => {
                    this.message.error('Error cargando familias de artículos');
                }
            });
        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.familiesService.discontectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openCreateFamilyDrawer() {
        this.fieldsReadOnly = false;
        this.familySelected = { id: 0, name: '', subFamilies: [] };
        this.drawerTitle = 'Creando nueva familia';
        this.isNewFamily = true;
        this.subFamiliesSelected = [];
        this.openDrawer();
    }

    watchFamily(id: number) {
        this.fieldsReadOnly = true;
        this.drawerTitle = 'Viendo familia ' + this.familySelected?.name;
        this.setData(id);
        this.openDrawer();
    }

    editFamily(id: number) {
        this.fieldsReadOnly = false;
        this.isNewFamily = false;
        this.drawerTitle = 'Editando familia ' + this.familySelected?.name;
        this.setData(id);
        this.openDrawer();
    }

    setData(id: number) {
        this.familySelected = this.families.find(
            (p) => p.id === id,
        ) as ArticleFamilyDto;

        let filterdFamilies: ArticleFamilyDto[] = [];

        if (this.familySelected.subFamilies) {
            filterdFamilies = this.families.filter((f) =>
                this.familySelected.subFamilies?.some((s) => s.childArticleFamilyId === f.id),
            );
        }

        this.subFamiliesSelected = this.families.filter((f) =>
            filterdFamilies.some((s) => s.id === f.id),
        );
    }

    cancelEditing() {
        this.closeDrawer();
    }

    async deleteFamily(familyId: number) {
        try {
            if (!window.confirm('¿Estás seguro de que quieres eliminar esta familia? Esta acción no se puede deshacer.')) {
                return;
            }

            const deletedFamily: ArticleFamilyDto = await this.familiesService.deleteFamily(familyId);

            const index = this.families.findIndex((p) => p.id === familyId);

            if (index < 0) {
                this.message.create('error', `No se ha podido eliminar la familia ${deletedFamily.name} con id ${familyId}, familia no encontrada`);

                return;
            }

            this.families.splice(index, 1);
            this.families = _.cloneDeep(this.families);

            this.message.create('success', 'Familia eliminada correctamente');
        } catch (e: any) {
            this.message.create('error', `No se ha podido eliminar la familia ${familyId}. Error: ${e.message}`);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    async saveEditing() {
        try {
            const familyToBeProcessed: ArticleFamilyDto = {
                id: this.familySelected.id,
                name: (<HTMLInputElement>document.getElementById('name')).value
                    ? ((<HTMLInputElement>document.getElementById('name'))
                        .value as string)
                    : '',
            };

            if (this.subFamiliesSelected.length > 0) {
                familyToBeProcessed.subFamilies = this.subFamiliesSelected.map(
                    (f) => {
                        return {
                            parentArticleFamilyId: this.familySelected.id,
                            childArticleFamilyId: f.id,
                        };
                    },
                );
            }

            if (!this.validForm(familyToBeProcessed)) {
                return;
            }

            if (this.isNewFamily) {
                const createdFamily: ArticleFamilyDto = await this.familiesService.createFamily(familyToBeProcessed);
                this.families.push(createdFamily);

                this.families = _.cloneDeep(this.families);

                this.message.create('success', 'Familia creada correctamente');
            } else {
                const updatedFamily: ArticleFamilyDto = await this.familiesService.updateFamily(familyToBeProcessed);
                const index = this.families.findIndex((r) => r.id === updatedFamily.id);

                if (index < 0) {
                    this.message.create('error', `No se ha podido actualizar la familia ${familyToBeProcessed.name} con id ${familyToBeProcessed.id}, familia no encontrada`,);

                    return;
                }

                this.families[index] = updatedFamily;
                this.families = _.cloneDeep(this.families);

                this.message.create('success', 'Familia actualizada correctamente');
            }

            this.closeDrawer();
        } catch (e: any) {
            this.message.create('error', `Error: ${e.message}`);
        }
    }

    validForm(articleFamily: ArticleFamilyDto): boolean {
        if (!articleFamily.name || articleFamily.name === '') {
            this.message.create('error', 'El nombre de la familia es obligatorio');

            return false;
        }
        return true;
    }
}
