import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/public/services/auth.service';
import { ScreenLoggedService } from 'src/app/core/services/screenLogged.service';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { environment } from 'src/environments/environment';
import { AuthMethod } from 'src/app/shared/models/auth.model';
import { isEmail } from 'src/app/shared/utils/utils';
import packageJson from '../../../../../package.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccessToken } from 'src/app/shared/dto/access.token.dto';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { ConfigurationService } from 'src/app/private/services/configuration.service';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    standalone: true,
    imports: [
        NzInputDirective,
        ReactiveFormsModule,
        FormsModule,
        ɵNzTransitionPatchDirective,
        NzInputGroupComponent,
        NzInputGroupWhitSuffixOrPrefixDirective,
        NzIconDirective,
        NzButtonComponent,
        NzWaveDirective,
        NzMessageModule,
    ],
})
export class LoginFormComponent implements OnChanges, AfterViewInit {
    loginScreen: ScreenDto | null = null;
    screenToBeLogged: Screen | null = null;
    user: string = '';
    codeSecurity!: number;
    password: string = '';
    passwordVisible = false;
    environment: any;
    version: any = packageJson.version;
    helper: JwtHelperService;
    showLoginButton: boolean = true;
    showLoginInput: boolean = true;

    @ViewChild('userInput') userInput!: ElementRef;

    constructor() {
        this.helper = new JwtHelperService();
        this.environment = _.cloneDeep(environment);
    }

    private readonly authService = inject(AuthService);
    private readonly screenLoggedService = inject(ScreenLoggedService);
    private readonly userLoggedService = inject(UserLoggedService);
    private readonly toast = inject(NzMessageService);
    private readonly router = inject(Router);
    private readonly configurationService = inject(ConfigurationService);

    ngOnChanges(changes: SimpleChanges) {
        const loginScreen: SimpleChange = changes['loginScreen'];

        if (
            loginScreen != undefined &&
            loginScreen.previousValue != loginScreen.currentValue
        ) {
            this.screenToBeLogged = _.cloneDeep(loginScreen.currentValue);
        }
    }

    ngAfterViewInit() {
        this.userInput.nativeElement.focus();
    }

    async login(): Promise<void> {
        if (this.user === '' || this.password === '') {
            this.toast.error('Porfavor, complete todos los campos');

            return;
        }

        try {
            let authToken$ = null;

            if (this.loginScreen) {
                authToken$ = this.authService.login(
                    isEmail(this.user) ? AuthMethod.EMAIL : AuthMethod.USER,
                    this.user,
                    { password: this.password, screenId: this.loginScreen.id }
                );
            } else {
                authToken$ = this.authService.login(
                    isEmail(this.user) ? AuthMethod.EMAIL : AuthMethod.USER,
                    this.user,
                    { password: this.password }
                );
            }

            const authToken: AccessToken = await lastValueFrom(authToken$);

            if (!authToken.accessToken || authToken.accessToken === '') {
                this.showLoginButton = false;
                this.showLoginInput = false;
            } else {
                const dataToken = this.helper.decodeToken(
                    authToken.accessToken,
                );

                this.userLoggedService.setUserLogged(
                    dataToken.user,
                    authToken.accessToken,
                );

                this.screenLoggedService.setScreen(
                    this.loginScreen ? this.loginScreen : null,
                );

                const configurations = await this.configurationService.getConfigurations().catch((error) => {
                    console.error(error);
                    this.toast.error('No se han podido cargar las configuraciones');
                });

                localStorage.setItem('configurations', JSON.stringify(configurations));

                this.router.navigate(['/production-orders']);
            }
        } catch (error: any) {
            this.toast.error(error.message);

            return;
        }
    }

    async verifyCode(): Promise<void> {
        if (+this.codeSecurity == 0) {
            this.toast.error('Porfavor, complete el código de seguridad');

            return;
        }

        try {
            let authToken$ = null;

            if (this.loginScreen) {
                authToken$ = this.authService.sendCode(
                    isEmail(this.user) ? AuthMethod.EMAIL : AuthMethod.USER,
                    this.user,
                    +this.codeSecurity,
                    { password: this.password, screenId: this.loginScreen.id }
                );
            } else {
                authToken$ = this.authService.sendCode(
                    isEmail(this.user) ? AuthMethod.EMAIL : AuthMethod.USER,
                    this.user,
                    +this.codeSecurity,
                    { password: this.password }
                );
            }

            const authToken = await lastValueFrom(authToken$);
            const dataToken = this.helper.decodeToken(authToken.accessToken);

            this.userLoggedService.setUserLogged(
                dataToken.user,
                authToken.accessToken,
            );

            this.screenLoggedService.setScreen(
                this.loginScreen ? this.loginScreen : null,
            );

            this.router.navigate(['/']);
        } catch (error: any) {
            this.toast.error('El codigo no es correcto');

            return;
        }
    }
}
