import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { StatusDto } from 'src/app/shared/dto/status.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { StatusesService } from '../../services/statuses.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-statuses',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzCheckboxModule,
        CommonModule,
        NzSelectModule,
    ],
    templateUrl: './statuses.component.html',
    styleUrl: './statuses.component.scss'
})
export class StatusesComponent implements OnInit, OnDestroy {
    readonly statusesService = inject(StatusesService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    statuses: StatusDto[] = [];
    drawerVisible: boolean = false;
    fieldsReadOnly: boolean = true;
    drawerTitle: string = 'Placeholder';
    statusSelected: StatusDto = new StatusDto();
    isNewStatus: boolean = false;
    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;
    isStartingStatus: boolean = false;
    isEndingStatus: boolean = false;
    isPauseStatus: boolean = false;
    initialOrder: number = -1;
    nextStatus: StatusDto = new StatusDto();

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.statusesService.setupWebSocket();
        this.statusesService.statuses$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (statuses) => {
                    this.statuses = statuses;
                },
                error: (e) => {
                    this.message.create('Error cargando estados', e.message);
                }
            });

        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.statusesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openCreateStatusDrawer() {
        this.fieldsReadOnly = false;
        this.statusSelected = new StatusDto();
        this.drawerTitle = 'Creando nuevo estado';
        this.isNewStatus = true;
        this.isStartingStatus = false;
        this.isEndingStatus = false;
        this.openDrawer();
    }

    seeStatus(id: number) {
        this.fieldsReadOnly = true;
        this.statusSelected = this.statuses.find((p) => p.id === id) as StatusDto;
        this.drawerTitle = 'Viendo estado ' + this.statusSelected?.name;
        this.setData();
        this.openDrawer();
    }

    editStatus(id: number) {
        this.fieldsReadOnly = false;
        this.isNewStatus = false;
        this.statusSelected = this.statuses.find((p) => p.id === id) as StatusDto;
        this.drawerTitle = 'Editando estado ' + this.statusSelected?.name;
        this.setData();
        this.openDrawer();
    }

    setData() {
        this.isStartingStatus = this.statusSelected.isStartingStatus ? this.statusSelected.isStartingStatus : false;
        this.isEndingStatus = this.statusSelected.isEndingStatus ? this.statusSelected.isEndingStatus : false;
        this.isPauseStatus = this.statusSelected.isPauseStatus ? this.statusSelected.isPauseStatus : false;
        this.nextStatus = this.statuses.find((s) => s.id === this.statusSelected.nextStatus) || new StatusDto();
    }

    cancelEditing() {
        this.closeDrawer();
    }

    async deleteStatus(statusId: number) {
        try {
            if (window.confirm('¿Estás seguro de que quieres eliminar este estado? Esta acción no se puede deshacer.')) {
                const deletedStatus: StatusDto = await this.statusesService.deleteStatus(statusId);

                const index = this.statuses.findIndex((p) => p.id === statusId);

                if (index < 0) {
                    this.message.create('error', `No se ha podido eliminar el estado ${deletedStatus.name} con id ${statusId}, estado no encontrado`);

                    return;
                }

                this.statuses.splice(index, 1);
                const data = [...this.statuses];
                this.statuses = data;

                this.message.create('success', 'Estado eliminado correctamente');
            }
        } catch (e: any) {
            this.message.create('error', `${e.message}`);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    async saveEditing() {
        const statusToBeProcessed: StatusDto = {
            id: this.statusSelected.id,
            name: (<HTMLInputElement>document.getElementById('name')).value
                ? ((<HTMLInputElement>document.getElementById('name'))
                    .value as string)
                : '',
            abbreviation: (<HTMLInputElement>document.getElementById('abbreviation')).value,
            isStartingStatus: this.isStartingStatus,
            isEndingStatus: this.isEndingStatus,
            isPauseStatus: this.isPauseStatus,
            nextStatus: !this.isEndingStatus ? this.nextStatus.id : undefined,
        };

        if (!this.isFormValid(statusToBeProcessed)) {
            return;
        }

        if (this.isNewStatus) {
            const createdStatus: StatusDto = await this.statusesService.createStatus(statusToBeProcessed);

            this.statuses.push(createdStatus);
            const data = [...this.statuses];
            this.statuses = data;

            this.message.create('success', 'Estado creado correctamente');
        } else {
            const updatedStatus: StatusDto = await this.statusesService.updateStatus(statusToBeProcessed);

            const index = this.statuses.findIndex((r) => r.id === updatedStatus.id);

            if (index < 0) {
                this.message.create('error', `No se ha podido actualizar el estado ${statusToBeProcessed.name} con id ${statusToBeProcessed.id}, estado no encontrado`);

                return;
            }

            this.statuses[index] = updatedStatus;
            const data = [...this.statuses];
            this.statuses = data;
            this.message.create('success', 'Estado actualizado correctamente');
        }

        this.closeDrawer();
    }

    isFormValid(status: StatusDto): boolean {
        if (!status.name || status.name === '') {
            this.message.create('error', 'El nombre del estado es obligatorio');
            return false;
        }

        if (!status.abbreviation || status.abbreviation === '') {
            this.message.create('error', 'La abreviatura del estado es obligatoria');
            return false;
        }

        if (status.isStartingStatus && status.isEndingStatus) {
            this.message.create('error', 'Un estado no puede ser inicial y final a la vez');
            return false;
        }

        if (status.isStartingStatus) {
            const existingStarting = this.statuses.find((s) => s.isStartingStatus);

            if (existingStarting && existingStarting.id !== status.id) {
                this.message.create('error', 'Solo puede haber un estado inicial');
                return false;
            }
        }

        if (!status.isStartingStatus) {
            const starting = this.statuses.filter((s) => s.isStartingStatus);

            if (starting.length > 0) {
                if (starting.some((s) => s.id === status.id) && starting.length === 1) {
                    this.message.create('error', 'Debe haber al menos un estado inicial');
                    return false;
                }
            } else {
                this.message.create('error', 'Debe haber al menos un estado inicial');
                return false;
            }
        }

        if (status.isEndingStatus) {
            const existingEnding = this.statuses.find((s) => s.isEndingStatus);

            if (existingEnding && existingEnding.id !== status.id) {
                this.message.create('error', 'Solo puede haber un estado final');
                return false;
            }
        }

        if (!status.isEndingStatus) {
            const ending = this.statuses.filter((s) => s.isEndingStatus);

            if (ending.length > 0) {
                if (ending.some((s) => s.id === status.id) && ending.length === 1) {
                    this.message.create('error', 'Debe haber al menos un estado final');
                    return false;
                }
            } else {
                this.message.create('error', 'Debe haber al menos un estado final');
                return false;
            }
        }

        if (status.isPauseStatus) {
            const existingPause = this.statuses.find((s) => s.isPauseStatus);

            if (existingPause && existingPause.id !== status.id) {
                this.message.create('error', 'Solo puede haber un estado de pausa');
                return false;
            }

            if (status.isStartingStatus) {
                this.message.create('error', 'Un estado de pausa no puede ser inicial');
                return false;
            }

            if (status.isEndingStatus) {
                this.message.create('error', 'Un estado de pausa no puede ser final');
                return false;
            }
        } else {
            const pause = this.statuses.filter((s) => s.isPauseStatus);

            if (pause.length > 0) {
                if (pause.some((s) => s.id === status.id) && pause.length === 1) {
                    this.message.create('error', 'Debe haber al menos un estado de pausa');
                    return false;
                }
            } else {
                this.message.create('error', 'Debe haber al menos un estado de pausa');
                return false;
            }
        }

        if (!status.isEndingStatus && !status.nextStatus && !status.isPauseStatus) {
            this.message.create('error', 'Si no es un estado final o de pausa, debe haber un estado siguiente');
            return false;
        }

        return true;
    }

    getNextStatusName(id: number | undefined): string {
        const nextStatus = this.statuses.find((s) => s.id === id);
        return nextStatus?.name ? nextStatus.name : '-';
    }
}
