<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Razones de tara" nzSubtitle="Razones de tara en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="createReason()">
                    Añadir razón
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #customersTable [nzData]="scrapReasons" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Texto</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of customersTable.data; track data.id) {
            <tr>
                <td>{{ data.text }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeReason(data.id)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editReason(data.id)" title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteReason(data.id)" title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Texto:</label>
            <input nz-input [value]="reasonSelected.text ? reasonSelected.text : ''" type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            <label>Razones de tara:</label>
            <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="causesSelector" nzMode="multiple"
                nzPlaceHolder="Selecciona" [(ngModel)]="selectedCauses" name="causesSelector"
                class="searchSelectors" [nzDisabled]="fieldsReadOnly">
                @for (f of causesDataSet; track f.id) {
                <nz-option [nzLabel]="f.text" [nzValue]="f"></nz-option>
                }
            </nz-select>
            <ng-template #causesSelector let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>
            <br><br>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            } @else {
            <!-- No metadata at the moment -->
            <!--
            <nz-divider></nz-divider>
            <h3>Metadatos</h3>
            -->
            }
        </form>
    </ng-container>
</nz-drawer>