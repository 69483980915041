import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ArticleBatchesService } from 'src/app/private/services/article-batches.service';
import { ProductionOrderProcessesService } from 'src/app/private/services/production-order-processes.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ProductionOrderDto } from 'src/app/shared/dto/production-order.dto';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
    selector: 'app-modal-select-article-batch',
    standalone: true,
    imports: [
        NzTableModule,
        NzModalFooterDirective,
        NzTagModule,
        NzIconModule
    ],
    templateUrl: './modal-select-article-batch.component.html',
    styleUrl: './modal-select-article-batch.component.scss'
})
export class ModalSelectArticleBatchComponent {
    @Output() emitService = new EventEmitter();

    private readonly modalService = inject(NzModalService);
    private readonly toast = inject(NzMessageService);
    private readonly articleBatchService = inject(ArticleBatchesService);
    private readonly orderProcessService = inject(ProductionOrderProcessesService);

    order: ProductionOrderDto;
    orderProcess: ProductionOrderProcessDto;

    loading: boolean = true;
    articleBatches: ArticleBatchDto[] = [];

    constructor(@Inject(NZ_MODAL_DATA) public data: any) {
        this.orderProcess = data?.data?.orderProcess;
        this.order = data?.data?.order;
    }

    async ngOnInit() {
        if (!this.orderProcess.article) {
            this.loading = false;
            this.toast.error('El proceso de producción no tiene un artículo asociado');
            return;
        }
        this.articleBatches = await this.articleBatchService.getBatchesByArticleId(this.orderProcess.article?.id as number);
        this.loading = false;
    }

    async generateNewBatch() {
        try {
            const batch: ArticleBatchDto = {
                quantity: 0,
                articleId: this.orderProcess.article?.id as number,
            };
    
            const createdArticleBatch: ArticleBatchDto = await this.articleBatchService.createArticleBatch(batch, this.orderProcess.id!)
            
            this.toast.success('Lote creado con éxito');
            this.emitService.next(createdArticleBatch);
            this.modalService.closeAll();
        } catch (e: any) {
            this.toast.error(`Error al crear lote. Error: ${e.message}`);
        }
    }

    async selectBatch(id: number) {
        const batch: ArticleBatchDto = this.articleBatches.find(ab => ab.id === id) as ArticleBatchDto;

        const responseOrderProcess: ProductionOrderProcessDto = await this.orderProcessService.setArticleBatch(this.orderProcess.id!, batch.id!);

        if (!responseOrderProcess) {
            this.toast.error('Error al seleccionar el lote');
            return;
        }

        this.emitService.next(batch);
        this.modalService.closeAll();
    }

    handleCancel() {
        this.modalService.closeAll();
    }
}
