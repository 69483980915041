import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ScrapDto } from 'src/app/shared/dto/scrap.dto';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root',
})
export class ScrapService extends BaseService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'scraps');
    }

    async getByArticleId(articleId: number): Promise<ScrapDto[]> {
        try {
            if (!articleId || articleId <= 0) {
                throw new Error('El id del articulo ha de ser mayor de 0');
            }

            const request = this.httpClient.get<ScrapDto[]>(this.url + `findByArticleId/${articleId}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido obtener las taras del articulo ${articleId ?? ''}. Error: ${e.message}`);
        }
    }

    async create(scrap: ScrapDto): Promise<ScrapDto> {
        try {
            const createdScrap = await lastValueFrom(
                this.httpClient.post<ScrapDto>(`${this.url}`, scrap)
            );

            return createdScrap as ScrapDto;
        } catch (e: any) {
            throw new Error(`No se ha podido crear la tara. Error: ${e.message}`);
        }
    }
}
