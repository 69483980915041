import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ScrapCauseDto } from 'src/app/shared/dto/scrap-cause.dto';
import { ScrapReasonDto } from 'src/app/shared/dto/scrap-reason.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ScrapCauseService } from '../../services/scrap-cause.service';
import { ScrapReasonService } from '../../services/scrap-reason.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-scrap-reasons',
    standalone: true,
    imports: [
        NzDividerModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDrawerModule,
        FormsModule,
        NzSelectModule
    ],
    templateUrl: './scrap-reasons.component.html',
    styleUrl: './scrap-reasons.component.scss'
})
export class ScrapReasonsComponent implements OnInit, OnDestroy {
    readonly reasonsService = inject(ScrapReasonService);
    readonly causesService = inject(ScrapCauseService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    drawerVisible: boolean = false;
    drawerTitle: string = 'Placeholder';
    fieldsReadOnly: boolean = true;
    reasonSelected: ScrapReasonDto = new ScrapReasonDto();
    scrapReasons: ScrapReasonDto[] = [];
    currentUser: UserDto = new UserDto();
    isNewCause: boolean = false;
    causesDataSet: ScrapCauseDto[] = [];
    selectedCauses: ScrapCauseDto[] = [];
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();


    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;
        await this.reasonsService.setupWebSocket();
        this.reasonsService.scrapReasons$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (reasons: ScrapReasonDto[]) => {
                    this.scrapReasons = reasons;
                    this.loadingTable = false;
                },
                error: () => {
                    this.loadingTable = false;
                    this.message.error('Error cargando razones de tara');
                }
            });

        this.causesService.setupWebSocket();
        this.causesService.scrapCauses$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (causes: ScrapCauseDto[]) => {
                    this.causesDataSet = causes;
                    this.loadingTable = false;
                },
                error: () => {
                    this.loadingTable = false;
                    this.message.error('Error cargando causas de tara');
                }
            });
    }

    ngOnDestroy(): void {
        this.reasonsService.disconnectWebSocket();
        this.causesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createReason() {
        this.fieldsReadOnly = false;
        this.reasonSelected = { id: 0, text: '' };
        this.drawerTitle = 'Creando nueva razón de tara';
        this.isNewCause = true;
        this.selectedCauses = [];
        this.openDrawer();
    }

    seeReason(id: number) {
        this.fieldsReadOnly = true;
        this.reasonSelected = this.scrapReasons.find(
            (p) => p.id === id,
        ) as ScrapReasonDto;
        this.drawerTitle = 'Viendo razón ' + this.reasonSelected?.text;
        this.setData();
        this.openDrawer();
    }

    editReason(id: number) {
        this.fieldsReadOnly = false;
        this.isNewCause = false;
        this.reasonSelected = this.scrapReasons.find(
            (p) => p.id === id,
        ) as ScrapReasonDto;
        this.drawerTitle = 'Editando razón ' + this.reasonSelected?.text;
        this.setData();
        this.openDrawer();
    }

    setData() {
        const phCauses: ScrapCauseDto[] = this.reasonSelected.scrapCauses as unknown as ScrapCauseDto[];
        this.selectedCauses = this.causesDataSet.filter((c) =>
            phCauses.some((s) => s.id === c.id),
        );
    }

    cancelEditing() {
        this.closeDrawer();
    }

    deleteReason(id: number) {
        if (
            window.confirm(
                '¿Estás seguro de que quieres eliminar esta razón de tara? Esta acción no se puede deshacer.',
            )
        ) {
            this.reasonsService.delete(id).subscribe({
                next: (reason: ScrapCauseDto) => {
                    try {
                        if (reason == undefined) {
                            this.message.create('error', `No se ha podido eliminar la razón de tara, razón de tara no encontrada`);

                            return;
                        }

                        const index = this.scrapReasons.findIndex((p) => p.id === id);

                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar la razón ${reason.text} con id ${id}, razón de tara no encontrada`);

                            return;
                        }

                        this.scrapReasons.splice(index, 1);
                        const data = [...this.scrapReasons];
                        this.scrapReasons = data;

                        this.message.create('success', 'Razón de tara eliminado correctamente',);
                    } catch (error: any) {
                        this.message.create('error', `Error eliminando razón de tara. Error: ${error.message}`);
                    }
                },
                error: (error: any) => {
                    this.message.create('error', `Error eliminando razón de tara, error de servidor. Error: ${error.message}`);
                },
            });
        } else {
            console.log('Cancelando eliminación de razón de tara ' + id);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customReasonDto: ScrapReasonDto = {
            id: this.reasonSelected.id,
            text: (<HTMLInputElement>document.getElementById('name')).value
                ? ((<HTMLInputElement>document.getElementById('name'))
                    .value as string)
                : '',
            scrapCauses: this.selectedCauses,
        };

        if (!this.validForm(customReasonDto)) {
            return;
        }

        if (this.isNewCause) {
            this.reasonsService.save(customReasonDto).subscribe({
                next: (reason: ScrapReasonDto) => {
                    try {
                        if (reason == undefined) {
                            throw new Error('Error creating scrap reason');
                        }

                        this.scrapReasons.push(reason);

                        const data = [...this.scrapReasons];
                        this.scrapReasons = data;

                        this.message.create('success', 'Razón de tara creada correctamente');

                        this.closeDrawer();
                    } catch (error: any) {
                        this.message.create('error', `Error creando razón de tara. Error: ${error.message}`);
                    }
                },
                error: (error: any) => {
                    this.message.create('error', `Error creando razón de tara, error de servidor. Error: ${error.message}`);
                }
            });
        } else {
            this.reasonsService.update(customReasonDto.id, customReasonDto).subscribe({
                next: (reason: ScrapCauseDto) => {
                    try {
                        if (reason == undefined) {
                            this.message.create('error', 'Error actualizando las razones de tara');

                            return;
                        }

                        const index = this.scrapReasons.findIndex(
                            (r) => r.id === reason.id,
                        );

                        if (index < 0) {
                            this.message.create('error', `No se ha podido actualizar la razón ${customReasonDto.text} con id ${customReasonDto.id}, razón de tara no encontrada`);

                            return;
                        }

                        this.scrapReasons[index] = reason;
                        const data = [...this.scrapReasons];
                        this.scrapReasons = data;

                        this.message.create('success', 'Razón de tara actualizada correctamente');

                        this.closeDrawer();
                    } catch (error: any) {
                        this.message.create('error', `Error actualizando razón de tara. Error: ${error.message}`);
                    }
                },
                error: (error: any) => {
                    this.message.create('error', `Error actualizando razón de tara, error de servidor. Error: ${error.message}`);
                }
            });
        }
    }

    validForm(cause: ScrapReasonDto): boolean {
        if (!cause.text) {
            this.message.create('error', 'El texto de la razón de tara es obligatorio',);
            return false;
        }
        return true;
    }
}
