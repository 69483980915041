import { Component, inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';

@Component({
    selector: 'app-private',
    templateUrl: './private.component.html',
    standalone: true,
    imports: [NavbarComponent, RouterOutlet],
})
export class PrivateComponent implements OnInit {
    environment: any;

    private readonly router = inject(Router);

    constructor() {}

    ngOnInit(): void {
        this.environment = _.cloneDeep(environment);
        if (this.router.url === '/') {
            this.router.navigate(['/production-orders']);
        }
    }
}
