import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';

@Component({
	selector: 'app-modal-ask-for-quantity-produced',
	standalone: true,
	imports: [
		FormsModule
	],
	templateUrl: './modal-ask-for-quantity-produced.component.html',
	styleUrl: './modal-ask-for-quantity-produced.component.scss'
})
export class ModalAskForQuantityProducedComponent {
	@Output() emitService = new EventEmitter();

	private readonly modalService = inject(NzModalService);
	private readonly toast = inject(NzMessageService);

	quantityProduced: number = 0;

	constructor(@Inject(NZ_MODAL_DATA) public data: any) { }

	handleFinishProductionButton() {
		if (this.quantityProduced < 0) {
			this.toast.error('La cantidad producida no puede ser negativa');
			return;
		}
		this.emitService.emit((<HTMLInputElement>document.getElementById('quantityProduced'))?.value
			? +((<HTMLInputElement>document.getElementById('quantityProduced'))?.value)
			: 0);
		this.modalService.closeAll();
	}

	handleCancelButton() {
		this.modalService.closeAll();
	}
}
