import { ArticleBatchDto } from './article-batch.dto';
import { ArticleDto } from './article.dto';
import { AssessDto } from './assess.dto';
import { MachineDto } from './machine.dto';
import { ProductionPauseDto } from './production-pause.dto';
import { ProductionProcessDto } from './production-process.dto';
import { ProductionTimeDto } from './production-time.dto';
import { RawMaterialDto } from './rawMaterial.dto';
import { ScrapDto } from './scrap.dto';
import { StatusDto } from './status.dto';

export class ProductionOrderProcessDto {
    id?: number;
    description!: string;
    statusId?: number;
    status?: StatusDto;
    quantityToProduce!: number;
    quantityProduced?: number;
    productionProcess!: ProductionProcessDto;
    productionTime?: ProductionTimeDto;
    productionPauses?: ProductionPauseDto[];
    rawMaterials!: RawMaterialDto[];
    machine!: MachineDto | null;
    machineId?: number;
    article?: ArticleDto;
    articleId?: number;
    articleBatch?: ArticleBatchDto;
    articleBatchId?: number;
    assess?: AssessDto | null;
    scraps?: ScrapDto[];
    qualityControlCounter?: number;
}
