import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { ArticleResponseDto } from 'src/app/shared/dto/pagination/articles-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ArticlesService extends BaseService {
    private articlesSubject = new BehaviorSubject<ArticleDto[]>([]);
    public articles$ = this.articlesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'articles');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.articlesSubject.next((await this.findAll()).articles);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLES_ROOM));
            this.socketService.getNotifications(Messages.ARTICLES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.articlesSubject.next(response.articles);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Artículos. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLES_ROOM));
    }

    async findAll(): Promise<ArticleResponseDto> {
        try {
            const request = this.httpClient.get<ArticleResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun artículo. Error: ${e.message}`);
        }
    }

    /**
     * Update the quantity produced of an article
     * @param articleId The id of the article
     * @param quantity The quantity to update
     * @returns The updated article
     */
    async updateQuantity(articleId: number, quantity: number): Promise<ArticleDto> {
        try {
            if (quantity < 0 || articleId <= 0) {
                throw new Error('La cantidad no puede ser inferior a 0 y el id del articulo ha de ser mayor de 0');
            }

            const request = this.httpClient.patch<ArticleDto>(`${this.url}/updateArticleQuantity/${articleId}`, { quantity: quantity });

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la cantidad del articulo ${articleId}: Error: ${e.message}`);
        }
    }

    /**
     * Update the quantities of a list of articles
     * @param articles An array of objects with the id of the article and the quantity to update
     * @returns The updated articles
     */
    async updateQuantities(articles: { id: number, quantity: number }[]): Promise<ArticleDto[]> {
        try {
            const updatedArticles: ArticleDto[] = await lastValueFrom(this.httpClient.patch<ArticleDto[]>(`${this.url}/updateArticlesQuantities`, articles));

            if (!updatedArticles) {
                throw new Error('Error updating articles quantities');
            }

            return updatedArticles;
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la cantidad para los articulos. Error: ${e.message}`);
        }
    }
}
