import { IdentityDocumentTypeDto } from './identity-document-type.dto';

export class CustomerDto {
    id!: number;
    externalId!: number;
    identityDocument!: string;
    identityDocumentId!: number;
    name!: string;
    businessName!: string;
    createdAt?: Date;
    synchronisedAt?: Date;
    updatedAt?: Date;
    identityDocumentType?: IdentityDocumentTypeDto;
    createdBy!: number;
}
