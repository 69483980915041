@if (rawMaterials.length > 0) {
  <div class="w-full h-full p-0 m-0">
    <nz-table
      #basicTable
      [nzData]="rawMaterials"
      class="m-0 p-0"
      [nzLoading]="isLoading"
      nzTableLayout="auto"
      [nzBordered]="true"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: '350px' }"
      >
      <thead>
        <tr>
            @if(this.processOrderIsCreated){
                <th nzWidth="40px"></th>
                <th>Lote</th>
            }
            <th>Artículo</th>
            <th>Cantidad</th>
            <th>Unidad de medida</th>
        </tr>
      </thead>
      <tbody>
        @for (data of basicTable.data; track data) {
          <tr>
            @if(this.processOrderIsCreated){
                <td>
                    <label
                      nz-checkbox
                      [ngModel]="selectedBatches.indexOf(data.id || -1) > -1"
                      (nzCheckedChange)="addRawMaterial(data.id || -1)"
                    ></label>
                  </td>
                  <td>{{ data.batchNumber }}</td>
            }
            <td>{{ data.name }}</td>
            <td>{{ data.quantity }}</td>
            <td>{{ data.measurementUnit }}</td>
          </tr>
        }
      </tbody>
    </nz-table>
  </div>
}
<div *nzModalFooter>
  <button
    (click)="handleCancel()"
    class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
    Cancelar
  </button>
  @if(this.processOrderIsCreated){
    <button
    type="submit"
    (click)="handleOk()"
    class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
    Aceptar
  </button>
  }
</div>
