<div class="ml-4 mr-4">
    <nz-table #batchTable [nzData]="articleBatches" [nzLoading]="loading">
        <thead>
            <tr>
                <th>Nº Serie</th>
                <th>Cantidad</th>
                @if (!orderProcess.status?.isEndingStatus) {
                <th>Acciones</th>
                }
                <th>Seleccionado</th>
            </tr>
        </thead>
        <tbody>
            @for (data of batchTable.data; track data.id) {
            <tr>
                <td>{{ data.batchNumber }}</td>
                <td>{{ data.quantity }}</td>
                @if (!orderProcess.status?.isEndingStatus) {
                <td>
                    <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button" (click)="selectBatch(data.id!)">
                        Usar este lote
                    </button>
                </td>
                }
                @if (data.id === orderProcess.articleBatch?.id) {
                <td>
                    <nz-tag nzColor="success">
                        <span nz-icon nzType="check-circle"></span>
                        <span>Seleccionado</span>
                    </nz-tag>
                </td>
                }
                @else {
                <td></td>
                }
            </tr>
            }
        </tbody>
    </nz-table>
    @if (!orderProcess.status?.isEndingStatus) {
    <div *nzModalFooter>
        <p>Si no quieres usar ninguno de estos, puedes generar uno nuevo</p>
        <button
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleCancel()">
            Cancelar
        </button>
        <button
            class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="generateNewBatch()">
            Generar un nuevo lote
        </button>
    </div>
    }
</div>