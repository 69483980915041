import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleTypeDto } from 'src/app/shared/dto/article-type.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ArticleTypeService } from '../../services/article-type.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-article-types',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzMessageModule,
        FormsModule
    ],
    templateUrl: './article-types.component.html',
    styleUrl: './article-types.component.scss'
})
export class ArticleTypesComponent implements OnInit, OnDestroy {
    readonly articleTypeService = inject(ArticleTypeService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    drawerVisible: boolean = false;
    drawerTitle: string = 'Placeholder';
    fieldsReadOnly: boolean = true;
    typeSelected: ArticleTypeDto = new ArticleTypeDto();
    articleTypes: ArticleTypeDto[] = [];
    isNewType: boolean = false;
    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.articleTypeService.setupWebSocket();
        this.articleTypeService.articleTypes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articleTypes) => {
                    this.articleTypes = articleTypes;
                    this.loadingTable = false;
                },
                error: () => {
                    this.message.error('Error cargando tipos de artículos');
                }
            });
    }

    ngOnDestroy(): void {
        this.articleTypeService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createType() {
        this.fieldsReadOnly = false;
        this.typeSelected = { id: 0, name: '' };
        this.drawerTitle = 'Creando nuevo tipo de artículo';
        this.isNewType = true;
        this.openDrawer();
    }

    seeType(id: number) {
        this.fieldsReadOnly = true;
        this.typeSelected = this.articleTypes.find(
            (p) => p.id === id,
        ) as ArticleTypeDto;
        this.drawerTitle = 'Viendo tipo ' + this.typeSelected?.name;
        this.setData();
        this.openDrawer();
    }

    editType(id: number) {
        this.fieldsReadOnly = false;
        this.isNewType = false;
        this.typeSelected = this.articleTypes.find(
            (p) => p.id === id,
        ) as ArticleTypeDto;
        this.drawerTitle = 'Editando tipo ' + this.typeSelected?.name;
        this.setData();
        this.openDrawer();
    }

    setData() {
        // This does nothing, it's just for consistency
    }

    cancelEditing() {
        this.closeDrawer();
    }

    deleteType(id: number) {
        if (
            window.confirm(
                '¿Estás seguro de que quieres eliminar este proceso de tipo de artículo? Esta acción no se puede deshacer.',
            )
        ) {
            this.articleTypeService.delete(id).subscribe({
                next: (type: ArticleTypeDto) => {
                    try {
                        if (type == undefined) {
                            this.message.create('error', 'No se ha podido eliminar el tipo, tipo no encontrado');
                            throw new Error('Article type not found');
                        }
                        const index = this.articleTypes.findIndex((p) => p.id === id);
                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar el tipo ${type.name} con id ${id}, tipo no encontrado`);
                            throw new Error('Article type not found');
                        }
                        this.articleTypes.splice(index, 1);
                        const data = [...this.articleTypes];
                        this.articleTypes = data;
                        this.message.create('success', 'Tipo de artículo eliminado correctamente');
                    } catch (error) {
                        this.message.create('error', 'Error eliminando el tipo de artículo');
                        console.error(error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error eliminando el tipo de artículo, error de servidor');
                    console.error(error);
                },
            });
        } else {
            console.log('Cancelando eliminación de tipo de artículo ' + id,);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customArticleTypeDto: ArticleTypeDto = {
            id: this.typeSelected.id,
            name: (<HTMLInputElement>document.getElementById('name')).value
                ? ((<HTMLInputElement>document.getElementById('name'))
                    .value as string)
                : '',
        };

        if (!this.validForm(customArticleTypeDto)) {
            return;
        }

        if (this.isNewType) {
            this.articleTypeService.save(customArticleTypeDto).subscribe({
                next: (type: ArticleTypeDto) => {
                    try {
                        if (type == undefined) {
                            throw new Error('Error creating articule type');
                        }
                        this.articleTypes.push(type);
                        const data = [...this.articleTypes];
                        this.articleTypes = data;
                        this.message.create('success', 'Tipo creado correctamente');
                        this.closeDrawer();
                    } catch (error) {
                        this.message.create('error', 'Error creando el tipo de artículo');
                        console.error('Error creating articule type', error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error creando el tipo de artículo, error de servidor');
                    console.error('Error creating articule type', error);
                },
            });
        } else {
            this.articleTypeService.update(customArticleTypeDto.id, customArticleTypeDto).subscribe({
                next: (type: ArticleTypeDto) => {
                    try {
                        if (type == undefined) {
                            this.message.create('error', 'No se ha podido actualizar el tipo, tipo no encontrado',);
                            throw new Error('Error updating article type');
                        }
                        const index = this.articleTypes.findIndex(
                            (r) => r.id === type.id,
                        );
                        if (index < 0) {
                            this.message.create('error', `No se ha podido actualizar el tipo ${customArticleTypeDto.name} con id ${customArticleTypeDto.id}, tipo no encontrado`,);
                            throw new Error('Article type not found');
                        }
                        this.articleTypes[index] = type;
                        const data = [...this.articleTypes];
                        this.articleTypes = data;
                        this.message.create('success', 'Tipo actualizada correctamente',);
                        this.closeDrawer();
                    } catch (error) {
                        this.message.create('error', 'Error actualizando el tipo de artículo',);
                        console.error('Error updating article type', error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error actualizando el tipo de artículo, error de servidor',);
                    console.error('Error updating article type', error);
                },
            });
        }
    }

    validForm(type: ArticleTypeDto): boolean {
        if (!type.name) {
            this.message.create(
                'error',
                'El nombre del tipo es obligatorio',
            );
            return false;
        }
        return true;
    }

}
