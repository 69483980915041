import { Routes } from "@angular/router";
import { PublicViewGuard } from "./core/guards/public-view.guard";
import { LoginComponent } from "./public/views/login/login.component";
import { ErrorApiComponent } from "./shared/components/error-api/error-api.component";
import { PrivateViewGuard } from "./core/guards/private-view.guard";
import { PrivateComponent } from "./private/private.component";
import { ArticleTypesComponent } from "./private/views/article-types/article-types.component";
import { ArticlesComponent } from "./private/views/articles/articles.component";
import { ChangePasswordComponent } from "./private/views/change-password/change-password.component";
import { CustomersComponent } from "./private/views/customers/customers.component";
import { MachinesComponent } from "./private/views/machines/machines.component";
import { ProductionOrderDetailComponent } from "./private/views/production-order-detail/production-order-detail.component";
import { ProductionOrderProcessDetailComponent } from "./private/views/production-order-process-detail/production-order-process-detail.component";
import { ProductionOrdersComponent } from "./private/views/production-orders/production-orders.component";
import { ProductionProcessesComponent } from "./private/views/production-processes/production-processes.component";
import { ProductionSubprocessesComponent } from "./private/views/production-subprocesses/production-subprocesses.component";
import { RoutesComponent } from "./private/views/routes/routes.component";
import { ScrapCausesComponent } from "./private/views/scrap-causes/scrap-causes.component";
import { ScrapReasonsComponent } from "./private/views/scrap-reasons/scrap-reasons.component";
import { ScreensComponent } from "./private/views/screens/screens.component";
import { UsersComponent } from "./private/views/users/users.component";
import { ArticleFamiliesComponent } from "./private/views/article-families/article-families.component";
import { StatusesComponent } from "./private/views/statuses/statuses.component";
import { MachineValuesComponent } from "./private/views/machine-values/machine-values.component";
import { ConfigurationComponent } from "./private/views/configuration/configuration.component";

export const routes: Routes = [
    {
        path: 'auth/login',
        component: LoginComponent,
        canActivate: [PublicViewGuard],
    },
    {
        path: '',
        component: PrivateComponent,
        canActivate: [PrivateViewGuard],
        children: [
            {
                path: 'screens',
                component: ScreensComponent,
                //canDeactivate: [PendingChangesGuard],
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'machines',
                component: MachinesComponent,
                //canDeactivate: [PendingChangesGuard],
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'articles',
                component: ArticlesComponent,
                //canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'article-types',
                component: ArticleTypesComponent,
                //canDeactivate: [PendingChangesGuard],
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'article-family',
                component: ArticleFamiliesComponent,
                //canDeactivate: [PendingChangesGuard],
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'production-orders',
                component: ProductionOrdersComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'production-orders/:idOrder',
                component: ProductionOrderDetailComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'production-orders/:idOrder/process/:idOrderProcess',
                component: ProductionOrderProcessDetailComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'production-subprocesses',
                component: ProductionSubprocessesComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'scrap-causes',
                component: ScrapCausesComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'scrap-reasons',
                component: ScrapReasonsComponent,
                //canDeactivate: [PendingChangesGuard]
                //TODO: DESCOMENTAR CUANDO TENGAMOS CLAROS LOS PERMISOS canActivate: [ ()=> hasViewPermissionGuard(['SCREEN_VIEW']) ]
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
            },
            {
                path: 'configuration',
                component: ConfigurationComponent,
            },
            {
                path: 'users',
                component: UsersComponent,
            },
            {
                path: 'clients',
                component: CustomersComponent,
            },
            {
                path: 'routes',
                component: RoutesComponent,
            },
            {
                path: 'productionprocesses',
                component: ProductionProcessesComponent,
            },
            {
                path: 'statuses',
                component: StatusesComponent,
            },
            {
                path: 'machine-values',
                component: MachineValuesComponent,
            },
        ],
    },
    { path: 'error', component: ErrorApiComponent },
    { path: '**', component: LoginComponent, canActivate: [PublicViewGuard] },
];
