import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { lastValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TOKEN } from '../../core/constants/storage';
import { Auth, AuthMethod } from 'src/app/shared/models/auth.model';
import { sha256 } from 'js-sha256';
import { ConfigurationService } from 'src/app/private/services/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    constructor( httpClient: HttpClient, ) {
        super(httpClient, 'auth/');
    }

    readonly router = inject(Router);
    readonly configurationService = inject(ConfigurationService);

    public getIp(): Observable<any> {
        return this.httpClient.get<any>(`${this.url}ip`);
    }

    public login(method: AuthMethod, username: string, optional?: { screenId?: number; password?: string }): Observable<any> {
        let body = null;

        let password: string | undefined = undefined;
        if (optional && optional.password) {
            password = sha256(optional.password);
        }

        switch (method) {
            case AuthMethod.USER:
            case AuthMethod.EMAIL:
                body = new Auth({ method, username, password: password });
                break;

            case AuthMethod.RFID:
                if (optional && optional.screenId) {
                    body = new Auth({
                        method,
                        username,
                        screenId: optional.screenId,
                    });
                } else {
                    body = new Auth({ method, username });
                }
                break;

            default:
                break;
        }

        return this.httpClient.post<any>(`${this.url}sendCode`, body);
    }

    public sendCode(method: AuthMethod, username: string, secCode: number, optional?: { screenId?: number, password?: string }): Observable<any> {
        let password: string | undefined = undefined;

        if (optional && optional.password) {
            password = sha256(optional.password);
        }

        const body = new Auth({ method, username, password: password, code: secCode });

        return this.httpClient.post<any>(`${this.url}login`, body);
    }

    public logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
    }

    public get token(): string | null {
        return localStorage.getItem(TOKEN);
    }

    async registerLogout(loggedUserId: number) {
        if (loggedUserId >= 0) {
            const logoutReq = this.httpClient.post<any>(`${this.url}logout`, {userId: loggedUserId});
            await lastValueFrom(logoutReq);
        }
    }
}
