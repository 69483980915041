import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import _ from 'lodash';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProductionProcessDto } from 'src/app/shared/dto/production-process.dto';
import { ProductionRouteDto } from 'src/app/shared/dto/production-route.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ProductionProcessesService } from '../../services/production-processes.service';
import { ProductionRoutesService } from '../../services/production-routes.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-routes',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzSelectModule,
        CdkDropList,
        CdkDrag,
    ],
    templateUrl: './routes.component.html',
    styleUrl: './routes.component.scss',
})
export class RoutesComponent implements OnInit, OnDestroy {
    readonly productionRoutesService = inject(ProductionRoutesService);
    readonly productionProcessesService = inject(ProductionProcessesService);
    readonly toast = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    routes: ProductionRouteDto[] = [];
    drawerVisible: boolean = false;
    drawerTitle: string = 'Placeholder';
    routeSelected: ProductionRouteDto = new ProductionRouteDto();
    fieldsReadOnly: boolean = false;
    isNewRoute: boolean = false;

    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;

    productionProcesses: ProductionProcessDto[] = [];
    productionProcessTableRows: ProductionProcessDto[] = [];
    previousProcessTableRows: ProductionProcessDto[] = [];
    selectedProcessess: ProductionProcessDto[] = [];

    private unsubscribe$ = new Subject<void>();


    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.productionRoutesService.setupWebSocket();
        this.productionRoutesService.productionRoutes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (routes) => {
                    this.routes = routes;
                },
                error: (error) => {
                    this.toast.create('Error al cargar las rutas de producción:', error.message);
                }
            });

        await this.productionProcessesService.setupWebSocket();
        this.productionProcessesService.productionProcessess$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (processess) => {
                    this.productionProcesses = processess;
                },
                error: (error) => {
                    this.toast.create('Error al cargar los procesos de producción:', error.message);
                }
            });

        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.productionRoutesService.disconnectWebSocket();
        this.productionProcessesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openCreateRouteDrawer() {
        this.fieldsReadOnly = false;
        this.routeSelected = new ProductionRouteDto();
        this.drawerTitle = 'Creando nueva ruta';
        this.isNewRoute = true;
        this.productionProcessTableRows = [];
        this.openDrawer();
    }

    seeRoute(id: number) {
        this.fieldsReadOnly = true;
        this.routeSelected = this.routes.find((r) => r.id === id) as ProductionRouteDto;
        this.drawerTitle = 'Viendo ruta ' + this.routeSelected?.name;

        this.setData();
        this.openDrawer();
    }

    editRoute(id: number) {
        this.fieldsReadOnly = false;
        this.isNewRoute = false;
        this.routeSelected = this.routes.find(
            (r) => r.id === id,
        ) as ProductionRouteDto;
        this.drawerTitle = 'Editando ruta ' + this.routeSelected?.name;
        this.setData();
        this.openDrawer();
    }

    setData() {
        this.productionProcessTableRows = [];

        this.productionProcessesService.sortProductionProcessByOrder(this.productionProcesses, this.routeSelected, this.selectedProcessess, this.productionProcessTableRows);

        this.previousProcessTableRows = _.cloneDeep(this.productionProcessTableRows);
    }

    async deleteRoute(routeId: number) {
        try {
            if (window.confirm('¿Estás seguro de que quieres eliminar esta ruta? Esta acción no se puede deshacer.')) {
                const deletedRoute: ProductionRouteDto = await this.productionRoutesService.deleteRoute(routeId);

                const index = this.routes.findIndex((r) => r.id === routeId);

                if (index < 0) {
                    this.toast.create('error', `No se ha podido eliminar la ruta ${deletedRoute.name} con id ${routeId}, ruta no encontrada`);

                    return;
                }

                this.routes.splice(index, 1);
                const data = [...this.routes];
                this.routes = data;

                this.toast.create('success', 'Ruta eliminada correctamente');
            }
        } catch (e: any) {
            this.toast.create('error', `${e.message}`);
        }
    }

    closeDrawer() {
        this.drawerVisible = false;
        this.selectedProcessess = [];
        this.previousProcessTableRows = [];
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    async saveEditing() {
        const routeToBeProcessed: ProductionRouteDto = {
            id: this.routeSelected.id,
            name: (<HTMLInputElement>document.getElementById('name')).value ? ((<HTMLInputElement>document.getElementById('name')).value as string) : '',
            productionProcessesIds: this.productionProcessTableRows.map((p) => p.id),
            description: (<HTMLInputElement>(document.getElementById('description'))).value ? ((<HTMLInputElement>document.getElementById('description')).value as string) : '',
            updatedBy: this.currentUser.id,
            createdBy: this.isNewRoute ? this.currentUser.id : this.routeSelected.createdBy
        };

        if (!this.validForm(routeToBeProcessed)) {
            return;
        }

        if (this.isNewRoute) {
            const createdRoute: ProductionRouteDto = await this.productionRoutesService.createRoute(routeToBeProcessed);

            this.routes.push(createdRoute);
            const data = [...this.routes];
            this.routes = data;

            this.toast.create('success', 'Ruta creada correctamente');
        } else {
            const updatedRoute: ProductionRouteDto = await this.productionRoutesService.updateRoute(routeToBeProcessed);

            const index = this.routes.findIndex((r) => r.id === updatedRoute.id);

            if (index < 0) {
                this.toast.create('error', `No se ha podido actualizar la ruta ${routeToBeProcessed.name} con id ${routeToBeProcessed.id}, ruta no encontrada`);

                return;
            }

            this.routes[index] = updatedRoute;
            const data = [...this.routes];
            this.routes = data;

            this.toast.create('success', 'Ruta actualizada correctamente');
        }

        this.closeDrawer();
    }

    cancelEditing() {
        this.closeDrawer();
    }

    onProductionProcessessChange(newSelectedProductionProcessess: ProductionProcessDto[]) {
        const addedProcess: ProductionProcessDto | undefined = this.previousProcessTableRows.length === 0
            ? newSelectedProductionProcessess[0]
            : newSelectedProductionProcessess.filter(o => !this.previousProcessTableRows.some(i => i.id === o.id))[0] ?? undefined;

        if (addedProcess) {
            this.productionProcessTableRows = [
                ...this.productionProcessTableRows,
                addedProcess as ProductionProcessDto
            ];
        }
        const removedProcess = this.previousProcessTableRows.find(process =>
            !newSelectedProductionProcessess.some(newProcess => newProcess.id === process.id)
        );

        if (removedProcess) {
            this.productionProcessTableRows = this.productionProcessTableRows.filter(row => row.id !== removedProcess.id);
        }

        this.previousProcessTableRows = [...newSelectedProductionProcessess];
    }

    validForm(route: ProductionRouteDto) {
        if (!route.name) {
            this.toast.create('error', 'El nombre de la ruta no puede estar vacío',);
            return false;
        }
        return true;
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (!this.fieldsReadOnly) {
            moveItemInArray(this.productionProcessTableRows, event.previousIndex, event.currentIndex);
        }
    }

    cloneProductionOrderProcess(idx: number): void {
        try {
            const newProductionProcess: ProductionProcessDto = _.cloneDeep(this.productionProcessTableRows[idx]) as ProductionProcessDto;

            if (!newProductionProcess) {
                this.toast.create('error', 'No se ha encontrado el proceso de producción para clonar');
            }

            this.previousProcessTableRows.push(newProductionProcess);
            this.productionProcessTableRows.push(newProductionProcess);
        } catch (error: any) {
            this.toast.create('error', `Error clonando el proceso de la orden de producción. Error: ${error.message}`);
        }
    }

    removeProductionProcess(idx: number): void {
        try {
            const processDeleted = this.productionProcessTableRows.splice(idx, 1);
            this.previousProcessTableRows.splice(idx, 1);

            if (!this.productionProcessTableRows.find(process => process.id === processDeleted[0].id)) {
                this.selectedProcessess = this.selectedProcessess.filter(p => p.id !== processDeleted[0].id);
            }

        } catch (error: any) {
            this.toast.create('error', `Error eliminando el proceso de producción. Error: ${error.message}`);
        }
    }
}
