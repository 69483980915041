<div class="ml-4 mr-4">
    <p>¿Cuanto se ha producido?</p>
    <label>Cantidad:</label>
    <input nz-input [value]="quantityProduced" type="number" pattern="\d*" class="searchSelectors" id="quantityProduced" />
    <div>
        <button
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleFinishProductionButton()">
            Finalizar producción
        </button>
        <button
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleCancelButton()">
            Cancelar
        </button>
    </div>
</div>