import {
    booleanAttribute,
    Component,
    EventEmitter,
    Inject,
    Output,
} from '@angular/core';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-modal-article-quantity',
    templateUrl: './modal-article-quantity.component.html',
    styleUrls: ['./modal-article-quantity.component.scss'],
})
export class ModalArticleQuantityComponent {
    @Output() emitService = new EventEmitter();

    showAddButtons: boolean = false;
    isLoading: boolean = true;
    quantity!: number;

    private _originalQuantity!: number;

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private toast: NzMessageService,
    ) {
        this.quantity = Number(data.data.quantity);
        this._originalQuantity = Number(data.data.quantity);
        this.showAddButtons = Boolean(data.data.showAddButtons);
    }

    handleOk(): void {
        if (
            this.quantity < 0 ||
            (!this.showAddButtons && this.quantity > this._originalQuantity)
        ) {
            this.toast.error(
                `La cantidad ha de ser mayor de 0 y menor que ${this._originalQuantity}`,
            );

            return;
        }

        this.emitService.next(this.quantity);
    }

    handleCancel(): void {
        this.emitService.next(-1);
    }

    add(num: number, op: string) {
        if (op === '+') {
            this.quantity += num;
        } else if (op === '-' && 1 < this.quantity) {
            this.quantity -= num;
        }
    }
}
