// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// /**
//  * LOCAL - Local
//  * Environment variables.
//  */
// export const environment = {
//     production: false,
//     preproduction: false,
//     development: false,
//     envName: 'local',
//     apiUrl: 'http://localhost:3000/api/v1/',
//     socketUrl: 'http://localhost:3000/',
//     socketPath: '/socket.io',
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const environment = {
    production: false,
    preproduction: false,
    development: true,
    apiUrl: 'https://dev.samarplast.mes.devoltec.com/api/v1/',
    socketUrl: 'https://dev.samarplast.mes.devoltec.com/',
    socketPath: '/socket.io',
};
