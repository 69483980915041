<div class="ml-4 mr-4">
    <nz-page-header
        class="site-page-header"
        nzTitle="Clientes"
        nzSubtitle="Clientes en el sistema"
    >
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="openCreateCustomerDrawer()"
                >
                    Añadir cliente
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #customersTable [nzData]="customers" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Nombre negocio</th>
                <th>Documento de identidad</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of customersTable.data; track data) {
                <tr>
                    <td>{{ data.name }}</td>
                    <td>{{ data.businessName }}</td>
                    <td>{{ data.identityDocument }}</td>
                    <td>
                        <div class="buttonsDnDTableContainer">
							<img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeCustomer(data.id)" title="Ver">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editCustomer(data.id)" title="Editar">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteCustomer(data.id)" title="Eliminar">
						</div>
                    </td>
                </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer
    [nzClosable]="false"
    [nzVisible]="drawerVisible"
    nzPlacement="right"
    [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()"
    [nzWidth]="'500px'"
    [nzMaskClosable]="fieldsReadOnly"
>
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="customerSelected.name ? customerSelected.name : ''" type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            <label>ID Externa:</label>
            <input nz-input [value]=" customerSelected.externalId ? customerSelected.externalId : '' " type="number" pattern="\d*" class="searchSelectors" [readOnly]="fieldsReadOnly" id="externalId" />

            <label>Documento de identidad:</label>
            <input nz-input [value]=" customerSelected.identityDocument ? customerSelected.identityDocument : '' " type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="identityDocument" />

            <label>Tipo de documento:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona" [(ngModel)]="identityDocumentIdSelected" name="documentTypeSelector"
                class="searchSelectors" [nzDisabled]="fieldsReadOnly">
                @for (i of identityDocumentTypes; track i) {
                <nz-option [nzLabel]="i" [nzValue]="i"></nz-option>
                }
            </nz-select>

            <label>Nombre de la empresa:</label>
            <input nz-input [value]="customerSelected.businessName ? customerSelected.businessName : ''" type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="businessName" />

            @if (!fieldsReadOnly) {
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                    Guardar
                </button>
                <nz-divider nzType="vertical"></nz-divider>
                <button
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="cancelEditing()"
                >
                    Cancelar
                </button>
            }
        </form>
    </ng-container>
</nz-drawer>
