import { DatePipe, NgClass } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CdTimerComponent, CdTimerModule } from 'angular-cd-timer';
import _ from 'lodash';
import { NzBreadCrumbComponent, NzBreadCrumbItemComponent } from 'ng-zorro-antd/breadcrumb';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NzDescriptionsComponent, NzDescriptionsItemComponent } from 'ng-zorro-antd/descriptions';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzPageHeaderBreadcrumbDirective, NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective, NzPageHeaderTagDirective } from 'ng-zorro-antd/page-header';
import { NzStatisticComponent } from 'ng-zorro-antd/statistic';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTrDirective } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { ORDERS } from 'src/app/core/constants/storage';
import { PermissionService } from 'src/app/core/services/permission.service';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { ConfigurationDto } from 'src/app/shared/dto/configuration.dto';
import { MachineMeasurementsValueDto } from 'src/app/shared/dto/machine-measurements-value.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ProductionOrderDto } from 'src/app/shared/dto/production-order.dto';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { ProductionTimeDto } from 'src/app/shared/dto/production-time.dto';
import { SetEndDateParams } from 'src/app/shared/dto/set-end-date-params.dto';
import { UpdateArticleQuantitiesParamsDto } from 'src/app/shared/dto/update-article-quantity-params.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ConfigurationTypesEnum } from 'src/app/shared/enums/configuration-types.enum';
import { CustomErrorStatusesEnum } from 'src/app/shared/enums/custom-error-statuses.enum';
import { PlcStatusEnum } from 'src/app/shared/enums/plc-status.enum';
import { ProductionOrderStateIdsEnum } from 'src/app/shared/enums/production-order-state-ids.enum';
import { ModalAskForQuantityProducedComponent } from '../../components/modals/modal-ask-for-quantity-produced/modal-ask-for-quantity-produced.component';
import { ModalCreatePauseComponent } from '../../components/modals/modal-create-pause/modal-create-pause.component';
import { ModalCreateScrapsComponent } from '../../components/modals/modal-create-scraps/modal-create-scraps.component';
import { ModalRawMaterialComponent } from '../../components/modals/modal-raw-material/modal-raw-material.component';
import { ModalSelectArticleBatchComponent } from '../../components/modals/modal-select-article-batch/modal-select-article-batch.component';
import { ModalWatchPausesComponent } from '../../components/modals/modal-watch-pauses/modal-watch-pauses.component';
import { ModalWatchScrapsComponent } from '../../components/modals/modal-watch-scraps/modal-watch-scraps.component';
import { ArticleBatchesService } from '../../services/article-batches.service';
import { ArticlesService } from '../../services/articles.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PauseService } from '../../services/pause.service';
import { ProductionOrderProcessesService } from '../../services/production-order-processes.service';
import { ProductionOrderService } from '../../services/production-order.service';
import { SensorsService } from '../../services/sensors.service';
import { ModalQualityControlComponent } from '../../components/modals/modal-quality-control/modal-quality-control.component';

@Component({
    selector: 'app-production-order-process-detail',
    templateUrl: './production-order-process-detail.component.html',
    styleUrl: './production-order-process-detail.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        CdTimerModule,
        NzPageHeaderTagDirective,
        NgClass,
        NzBreadCrumbComponent,
        NzPageHeaderBreadcrumbDirective,
        NzBreadCrumbItemComponent,
        RouterLink,
        NzPageHeaderContentDirective,
        NzDescriptionsComponent,
        NzDescriptionsItemComponent,
        NzCardComponent,
        NzStatisticComponent,
        NzTableComponent,
        NzTbodyComponent,
        NzTrDirective,
        NzTableCellDirective,
        DatePipe,
        NzMessageModule,
        NzModalModule,
    ],
})
export class ProductionOrderProcessDetailComponent implements OnInit, OnDestroy {
    idOrder: number;
    idOrderProcess: number;
    currentPause: ProductionPauseDto | null = null;
    productionTime: ProductionTimeDto = new ProductionTimeDto();

    order!: ProductionOrderDto;
    orderProcess!: ProductionOrderProcessDto;
    machineMeasurements: MachineMeasurementsValueDto[] = [];
    configurations: ConfigurationDto[] = [];

    timeLoaded: boolean = false;
    processInitiated: boolean = false;
    loading: boolean = true;
    isQualityControlActivated: boolean = false;
    qualityControlPerSeconds: number = 0;
    qualityControlPerQuantityProduced: number = 0;
    showingQualityControl: boolean = false;
    valoresPresentacion: any[] = [];

    sensorValue: number = 0;

    @ViewChild('basicTimer', { static: false }) timer!: CdTimerComponent;
    userLogged!: UserDto;

    private sensorConfigKey: string = 'productionOrdersProcessesDetail.sensors';

    private unsubscribe$ = new Subject<void>();

    private readonly activeRoute = inject(ActivatedRoute);
    private readonly userLoggedService = inject(UserLoggedService);
    private readonly permissionService = inject(PermissionService);
    private readonly pauseService = inject(PauseService);
    private readonly productionOrderService = inject(ProductionOrderService);
    private readonly productionOrderProcessesService = inject(ProductionOrderProcessesService);
    private readonly modalService = inject(NzModalService);
    private readonly toast = inject(NzMessageService);
    private readonly articleService = inject(ArticlesService);
    private readonly articleBatchService = inject(ArticleBatchesService);
    private readonly sensorsService = inject(SensorsService);
    private readonly configurationService = inject(ConfigurationService);

    constructor() {
        this.idOrder = parseInt(this.activeRoute.snapshot.params['idOrder']);
        this.idOrderProcess = parseInt(this.activeRoute.snapshot.params['idOrderProcess']);
        this.userLogged = _.cloneDeep(this.userLoggedService.userLogged);

        if (!this.idOrder || this.idOrder < 0 || !this.idOrderProcess || this.idOrderProcess < 0 || !this.userLogged) {
            throw new Error('Algo ha ido mal obteniendo el id de la orden, del proceso o el usuario logueado.');
        }
    }

    async ngOnInit(): Promise<void> {
        try {
            let productionOrders = [] as ProductionOrderDto[];
            await this.sensorsService.setupWebSocket();

            await this.productionOrderService.setupWebSocket();

            this.productionOrderService.orders$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (orders) => {
                        productionOrders = orders;
                        if (productionOrders && productionOrders.length > 0) {
                            this.order = productionOrders.find((o) => o.id === this.idOrder) ?? new ProductionOrderDto();

                            this.orderProcess = (this.order?.productionOrderProcesses as ProductionOrderProcessDto[]).find(
                                (p) => p.id === this.idOrderProcess,
                            ) ?? new ProductionOrderProcessDto();

                            if (Object.keys(this.orderProcess).length <= 0) {
                                this.loading = false;
                                throw new Error(`No se ha encontrado el proceso ${this.idOrderProcess} en la orden ${this.idOrder}`);
                            } else {
                                this.setTimer();
                            }

                            this.machineMeasurements = this.orderProcess.article?.machineMeasurementValues ?? [];
                        }

                        this.loading = false;
                    },
                    error: () => {
                        this.toast.error('Error cargando la orden de producción');
                    }
                });
            this.configurations = await this.configurationService.getConfigurations();
            const qualityControlActiveConfig: ConfigurationDto | undefined = this.configurations.find(
                (c) => c.key === 'qualityControl.activated',
            );
            if (qualityControlActiveConfig != null && qualityControlActiveConfig.type === ConfigurationTypesEnum.BOOLEAN) {
                this.isQualityControlActivated = JSON.parse(qualityControlActiveConfig.value as string);
                if (this.isQualityControlActivated) {
                    const qualityControlMinutesConfig: ConfigurationDto | undefined = this.configurations.find(
                        (c) => c.key === 'qualityControl.delayInSeconds',
                    );
                    if (qualityControlMinutesConfig != null && qualityControlMinutesConfig.type === ConfigurationTypesEnum.NUMBER) {
                        this.qualityControlPerSeconds = +qualityControlMinutesConfig.value;
                    }

                    const qualityControlQuantityProducedConfig: ConfigurationDto | undefined = this.configurations.find(
                        (c) => c.key === 'qualityControl.delayInQuantityProduced',
                    );
                    if (qualityControlQuantityProducedConfig != null && qualityControlQuantityProducedConfig.type === ConfigurationTypesEnum.NUMBER) {
                        this.qualityControlPerQuantityProduced = +qualityControlQuantityProducedConfig.value;
                    }
                }
            }

            const useSensorConfig: ConfigurationDto = await this.configurationService.getConfigurationByKey(this.sensorConfigKey);

            if (useSensorConfig.type === ConfigurationTypesEnum.BOOLEAN && JSON.parse(useSensorConfig.value as string)) {
                this.sensorsService.machineSensors$
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe({
                        next: (sensor: any) => {
                            if (sensor == undefined || this.idOrderProcess !== +sensor.orderProcessId) {
                                return;
                            }

                            this.sensorValue = sensor.value as number ?? 0;

                            if (!this.orderProcess.status?.isEndingStatus && !this.orderProcess.status?.isPauseStatus && sensor.status.toLowerCase() === PlcStatusEnum.PAUSED.toLowerCase()) {
                                this.pauseProcess();
                            }

                            if (this.orderProcess.status?.isPauseStatus && sensor.status.toLowerCase() === PlcStatusEnum.RESUMED.toLowerCase()) {
                                this.startProduction(); // ToDo: Should this be awaitable or is not needed?
                            }
                        }
                    });
            }
        } catch (e: any) {
            this.toast.error(`${e.message}`);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.productionOrderService.disconnectWebSocket();
        this.sensorsService.disconnectWebSocket();
    }

    showButton(btnName: string): boolean {
        const permissionMainKey: string = 'productionOrderProcess';

        return this.permissionService.canRead(permissionMainKey, `${permissionMainKey}.${btnName}`);
    }

    openRawMaterialModal() {
        const modal = this.modalService.create({
            nzTitle: `Materias primas - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalRawMaterialComponent,
            nzStyle: { width: '90%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    orderProcessId: this.orderProcess?.id,
                    rawMaterials: this.orderProcess?.rawMaterials
                }
            }
        });

        modal.componentInstance?.emitService.subscribe((value) => {
            modal.close();

            const modifiedRawMaterials: ArticleBatchDto[] =
                value as ArticleBatchDto[];

            if (modifiedRawMaterials.length > 0) {
                this.orderProcess.rawMaterials = modifiedRawMaterials;

                const ordersStr = localStorage.getItem(ORDERS);

                if (ordersStr) {
                    const productionOrders = JSON.parse(ordersStr) as ProductionOrderDto[];

                    const orderIndex = productionOrders.findIndex(
                        (o) => o.id === this.idOrder
                    );

                    const processIndex = (productionOrders[orderIndex].productionOrderProcesses as ProductionOrderProcessDto[]).findIndex(
                        (p) => p.id === this.idOrderProcess
                    );

                    (productionOrders[orderIndex].productionOrderProcesses as ProductionOrderProcessDto[])[
                        processIndex
                    ].rawMaterials = modifiedRawMaterials;

                    localStorage.setItem(ORDERS, JSON.stringify(productionOrders));
                }
            }

            return;
        });
    }

    finalProductButtonAction() {
        this.openBatchModal(false);
    }

    openPausesModal() {
        this.modalService.create({
            nzTitle: `Ver Pausas - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalWatchPausesComponent,
            nzStyle: { width: '90%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    processId: this.orderProcess?.id,
                    article: this.orderProcess?.article
                }
            }
        });
    }

    openScrapsModal() {
        this.modalService.create({
            nzTitle: `Ver Taras - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalWatchScrapsComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    scraps: this.orderProcess?.scraps,
                    article: this.orderProcess?.article,
                    processId: this.orderProcess?.id,
                    processDescription: this.orderProcess?.description,
                    operatorName: this.userLogged.name
                }
            }
        });
    }

    openCreateScrapsModal() {
        this.modalService.create({
            nzTitle: `Nueva Tara - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalCreateScrapsComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    processId: this.orderProcess?.id,
                    processDescription: this.orderProcess?.description
                }
            }
        });
    }

    pauseProcess() {
        if (!this.processInitiated) {
            this.toast.error('No se puede pausar una orden que no ha sido iniciada');
            return;
        }

        if (this.productionTime?.endDate) {
            this.toast.error('La producción ya ha sido finalizada');
            return;
        }

        if (this.currentPause != null) {
            this.toast.error('Ya hay una pausa en curso');
            return;
        }

        if (!this.timeLoaded) {
            return;
        }

        const modal = this.modalService.create({
            nzTitle: `Crear pausa - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalCreatePauseComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    orderProcessId: this.orderProcess?.id,
                    orderProcessDescription: this.orderProcess?.description,
                    previousStatusId: this.orderProcess?.statusId
                }
            }
        });

        modal.componentInstance?.emitService.subscribe(async (response: ProductionOrderProcessDto) => {
            if (!response.productionPauses) {
                throw new Error('There is no production pauses in the response');
            }

            const pause: ProductionPauseDto = response.productionPauses.find((p: ProductionPauseDto) => !p.endDate) as ProductionPauseDto;

            if (!pause) {
                this.toast.error('Error al crear la pausa');
                return;
            }

            this.currentPause = pause;

            this.orderProcess = response;

            this.timer.stop();

            await this.productionOrderService.updateOrderStatus(this.order.id, ProductionOrderStateIdsEnum.PAUSED);

            this.toast.success('Pausa realizada');
        });
    }

    async showStartProductionDialog(): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalService.confirm({
                nzTitle: '<i>¿Seguro que quieres iniciar la producción?</i>',
                nzContent: '<b>Una vez iniciada no podrás editar la orden de producción</b>',
                nzClosable: false,
                nzOkText: 'Iniciar producción',
                nzOnOk: () => { resolve(true); },
                nzCancelText: 'Cancelar',
                nzOnCancel: () => { resolve(false); }
            });
        });
    }

    async startProduction() {
        try {
            let hasEnoughStock: boolean = false;

            if (this.orderProcess.status?.isStartingStatus) {
                if (!await this.showStartProductionDialog()) {
                    return;
                }

                hasEnoughStock = await this.productionOrderProcessesService.hasEnoughStock(this.orderProcess.rawMaterials, this.orderProcess.quantityToProduce);

                if (!hasEnoughStock) {
                    this.toast.error('No hay suficiente stock');
                    return;
                }
            } else if (this.productionTime?.endDate && this.order.status?.isEndingStatus) {
                this.toast.error('La producción ya ha sido finalizada');
                return;
            }

            if (!this.timeLoaded) {
                return;
            }

            let infoMessage: string = '';

            if (this.currentPause?.id && this.currentPause?.id > 0) {
                const params: SetEndDateParams = {
                    pauseId: this.currentPause?.id,
                    resumedById: this.userLogged.id,
                    productionOrderProcessId: this.idOrderProcess
                };

                this.orderProcess = await this.productionOrderProcessesService.setOrderProcessAsResumed(params);

                this.currentPause = null;

                infoMessage = 'Producción reanudada';
            } else if (this.orderProcess.status?.isStartingStatus) {
                this.orderProcess = await this.productionOrderProcessesService.setProductionProcessAsStarted(this.order.id, this.idOrderProcess, this.userLogged.id);

                if (hasEnoughStock) {
                    await this.subtractQuantity();
                }

                infoMessage = 'Producción iniciada';
            } else {
                this.toast.error('No se puede iniciar la producción');
                return;
            }

            this.order = await this.productionOrderService.updateOrderStatus(this.order.id, ProductionOrderStateIdsEnum.IN_PROGRESS);

            this.timer.resume();
            this.processInitiated = true;

            this.toast.success(`${infoMessage}`);
        } catch (e: any) {
            let toastErrorMessage = '';

            if (e.status === CustomErrorStatusesEnum.ALREADY_EXIST_PRODUCTION_TIME) {
                toastErrorMessage = 'La producción ya ha sido iniciada';
            } else if (e.status === CustomErrorStatusesEnum.MACHINE_ALREADY_IN_USE) {
                toastErrorMessage = `La máquina ${this.orderProcess?.machineId} ya está en uso`;
            } else if (e.status === CustomErrorStatusesEnum.PARENT_PROCESS_NOT_FINISHED) {
                toastErrorMessage = 'El proceso anterior no ha sido finalizado';
            } else {
                toastErrorMessage = `Error al iniciar la producción. Error: ${e.message}`;
            }

            this.toast.error(toastErrorMessage);
        }
    }

    /**
     * Finish the production process, return true if the process was finished successfully
     * @returns Promise<boolean>
     */
    async finishProduction(): Promise<boolean> {
        try {
            this.orderProcess = await this.productionOrderProcessesService.setProductionProcessAsFinished(this.order.id, this.idOrderProcess, this.userLogged.id);

            this.productionTime = await this.productionOrderProcessesService.getProductionTimeByOrderProcessId(this.idOrderProcess);

            this.toast.success('Producción finalizada');

            this.timer.stop();

            const lastProcess: ProductionOrderProcessDto = this.order?.productionOrderProcesses![this.order.productionOrderProcesses!.length - 1];

            if (lastProcess.id === this.idOrderProcess) {
                this.order = await this.productionOrderService.updateOrderStatus(this.order.id, ProductionOrderStateIdsEnum.FINISHED);
            } else {
                this.order = await this.productionOrderService.updateOrderStatus(this.order.id, ProductionOrderStateIdsEnum.IN_PROGRESS);
            }

            return true;
        } catch (e: any) {
            this.toast.error(e.message);

            return false;
        }
    }

    async stopButtonAction() {
        if (!(await this.showStopProductionDialog())) {
            return;
        }

        if (!this.processInitiated) {
            this.toast.error('No se puede finalizar una orden que no ha sido iniciada');
            return;
        }

        if (this.productionTime?.endDate) {
            this.toast.error('La producción ya ha sido finalizada');
            return;
        }

        this.openAskForQuantityProducedModal();
    }

    async finishProductionWhenBatches() {
        try {
            const quantity = this.orderProcess?.articleBatch?.quantity! + this.orderProcess.quantityProduced!;
            const updatedArticleBatch: ArticleBatchDto = await this.articleBatchService.updateQuantity(this.orderProcess?.articleBatch?.id!, quantity);

            this.orderProcess.articleBatch = updatedArticleBatch;

            this.order.productionOrderProcesses = this.order.productionOrderProcesses?.map((p) => {
                if (p.id === this.orderProcess.id) {
                    p.articleBatch = updatedArticleBatch;
                }

                return p;
            });

            this.productionOrderService.updateOrderLocalStorage(this.order, false);

            await this.finishProduction();
        } catch (error: any) {
            this.toast.error(`Error finalizando proceso. Error: ${error.message}`);
        }
    }

    async finishProductionWhenNoBatches() {
        try {
            if (!(await this.finishProduction())) {
                return;
            }

            if (this.orderProcess.article?.quantity !== undefined) {
                if (this.orderProcess.quantityProduced! > 0) {
                    const updatedArticle: ArticleDto = await this.articleService.updateQuantity(this.orderProcess.article.id, this.orderProcess.article.quantity + this.orderProcess.quantityProduced!);

                    this.orderProcess.article = updatedArticle;

                    this.order.productionOrderProcesses = this.order.productionOrderProcesses?.map((p) => {
                        if (p.id === this.orderProcess.id) {
                            p.article = updatedArticle;
                        }

                        return p;
                    });

                    this.productionOrderService.updateOrderLocalStorage(this.order, false);
                }
            } else {
                throw new Error('Error al actualizar la cantidad de producto, la cantidad de producto no puede ser nula');
            }
        } catch (error: any) {
            this.toast.error(`Error al actualizar la cantidad de producto. Error: ${error.message}`);
        }
    }

    openBatchModal(isFromStopButton: boolean) {
        const modal = this.modalService.create({
            nzTitle: `Selecciona un lote - Orden ${this.orderProcess?.id} ${this.orderProcess?.description ? '| ' + this.orderProcess?.description : ''}`,
            nzContent: ModalSelectArticleBatchComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    order: this.order,
                    orderProcess: this.orderProcess,
                }
            }
        });

        modal.componentInstance?.emitService.subscribe(async (value: ArticleBatchDto) => {
            this.orderProcess.articleBatch = value;
            this.order.productionOrderProcesses = this.order.productionOrderProcesses?.map((p) => {
                if (p.id === this.orderProcess.id) {
                    p.articleBatch = value;
                }

                return p;
            });

            if (isFromStopButton) {
                await this.finishProductionWhenBatches();
            }

            this.productionOrderService.updateOrderLocalStorage(this.order, false);
        });
    }

    async showStopProductionDialog(): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalService.confirm({
                nzTitle: '<i>¿Seguro que quieres finalizar la producción?</i>',
                nzContent: '<b>Una vez finalizada no podrás editar la orden de producción</b>',
                nzClosable: false,
                nzOkText: 'Finalizar producción',
                nzOnOk: () => { resolve(true); },
                nzCancelText: 'Cancelar',
                nzOnCancel: () => { resolve(false); }
            });
        });
    }

    openAskForQuantityProducedModal() {
        const modal = this.modalService.create({
            nzTitle: `¿Caunto se ha producido? - Orden ${this.orderProcess?.id} ${this.orderProcess?.description ? '| ' + this.orderProcess?.description : ''}`,
            nzContent: ModalAskForQuantityProducedComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    order: this.order,
                    orderProcess: this.orderProcess
                }
            }
        });

        modal.componentInstance?.emitService.subscribe(async (value: number) => {
            this.orderProcess = await this.productionOrderProcessesService.updateQuantityProduced(this.orderProcess.id as number, value);

            this.order.productionOrderProcesses = this.order.productionOrderProcesses?.map((p) => {
                if (p.id === this.orderProcess.id) {
                    p.quantityProduced = value;
                }

                return p;
            });

            this.productionOrderService.updateOrderLocalStorage(this.order, false);

            if (!this.orderProcess.article?.useBatches) {
                await this.finishProductionWhenNoBatches();
            } else {
                if (!this.orderProcess.articleBatch) {
                    this.openBatchModal(true);
                } else {
                    await this.finishProductionWhenBatches();
                }
            }
        });
    }

    private async setTimer() {
        const pauses: ProductionPauseDto[] = await this.pauseService.getByProcessId(this.idOrderProcess);
        this.currentPause = pauses.find((p) => !p.endDate) ?? null;

        this.productionTime = await this.productionOrderProcessesService.getProductionTimeByOrderProcessId(this.idOrderProcess);

        const currentTimeMilliseconds: number = await this.productionOrderProcessesService.getTimerByProductionOrderProcessId(this.idOrderProcess);

        if (currentTimeMilliseconds > 0) {
            this.timer.startTime = Math.round(Math.round((currentTimeMilliseconds / 1000 + Number.EPSILON) * 100) / 100) - 1;
            this.processInitiated = true;
        } else {
            this.timer.startTime = 0;
        }

        this.timer.start();

        if (this.currentPause != null || currentTimeMilliseconds === 0 || this.productionTime?.endDate) {
            this.timer.stop();
        }

        this.timeLoaded = true;
    }

    handleOnTickTimer() {
        if (!this.isQualityControlActivated || this.orderProcess.status?.isEndingStatus || this.showingQualityControl || !this.orderProcess.productionProcess.qualityControl) {
            return;
        }
        const qualityControlCounter: number | undefined = this.orderProcess.qualityControlCounter;
        const elapsedTimeInSeconds: number = this.timer.get().tick_count;

        if (qualityControlCounter && this.qualityControlPerSeconds > 0 && elapsedTimeInSeconds > (qualityControlCounter + (this.qualityControlPerSeconds))) {
            this.makeQualityControl();
            return;
        } else if (qualityControlCounter == null) {
            this.makeQualityControl();
            return;
        }

        if (this.qualityControlPerQuantityProduced > 0 && this.qualityControlPerQuantityProduced < this.orderProcess.quantityProduced!) {
            this.makeQualityControl();
            return;
        }
    }

    makeQualityControl() {
        this.openQualityControlModal();
        this.showingQualityControl = true;
    }

    openQualityControlModal() {
        const modal = this.modalService.create({
            nzTitle: `Control de calidad - Orden ${this.orderProcess?.id} | ${this.orderProcess?.description}`,
            nzContent: ModalQualityControlComponent,
            nzStyle: { width: '90%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzMaskClosable: false,
        });

        modal.componentInstance?.emitService.subscribe(async (value) => {
            this.showingQualityControl = false;
            const tickCount: number = this.timer.get().tick_count;
            await this.productionOrderProcessesService.updateQualityControlCounter(tickCount, this.orderProcess.id as number);
            this.orderProcess.qualityControlCounter = tickCount;
            this.order.productionOrderProcesses = this.order.productionOrderProcesses?.map((p) => {
                if (p.id === this.orderProcess.id) {
                    p.qualityControlCounter = tickCount;
                }
                return p;
            });
            this.productionOrderService.updateOrderLocalStorage(this.order, false);
        });
    }

    private async subtractQuantity() {
        try {
            const articlesAndQuantitiesFromRawMaterials: UpdateArticleQuantitiesParamsDto[] = [];
            const batchesAndQuantitiesFromRawMaterials: UpdateArticleQuantitiesParamsDto[] = [];

            for (let rawMaterial of this.orderProcess.rawMaterials) {
                if (rawMaterial.article != null && rawMaterial.articleId != null) {
                    articlesAndQuantitiesFromRawMaterials.push({
                        id: rawMaterial.articleId,
                        quantity: rawMaterial.article.quantity - (rawMaterial.quantity * this.orderProcess.quantityToProduce)
                    });
                } else if (rawMaterial.articleBatch != null && rawMaterial.articleBatchId != null) {
                    batchesAndQuantitiesFromRawMaterials.push({
                        id: rawMaterial.articleBatchId,
                        quantity: rawMaterial.articleBatch.quantity - (rawMaterial.quantity * this.orderProcess.quantityToProduce)
                    });
                } else {
                    throw new Error(`No article or batch added for raw material ${rawMaterial.id}`);
                }
            }

            let updatedArticles: ArticleDto[] = [];

            if (articlesAndQuantitiesFromRawMaterials.length > 0) {
                updatedArticles = await this.articleService.updateQuantities(articlesAndQuantitiesFromRawMaterials);
            }

            let updatedBatches: ArticleBatchDto[] = [];

            if (batchesAndQuantitiesFromRawMaterials.length > 0) {
                updatedBatches = await this.articleBatchService.updateQuantities(batchesAndQuantitiesFromRawMaterials);
            }

            this.productionOrderService.updateArticleAndBatchInOrdersLocalStorage(updatedArticles, updatedBatches);
        } catch (error) {
            throw error;
        }
    }
}
