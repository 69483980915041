export enum Messages {
    ARTICLE_BATCHES_CHANGED = 'article-batches-changed',
    ARTICLE_FAMILIES_CHANGED = 'article-families-changed',
    ARTICLE_TYPES_CHANGED = 'article-types-changed',
    ARTICLES_CHANGED = 'articles-changed',
    ASSESS_CHANGED = 'assess-changed',
    CONFIGURATIONS_CHANGED = 'configurations-changed',
    CUSTOMERS_CHANGED = 'customers-changed',
    MACHINE_MEASUREMENTS_VALUES_CHANGED = 'machine-measurements-values-changed',
    MACHINE_MEASUREMENTS_CHANGED = 'machine-measurements-changed',
    MACHINES_CHANGED = 'machines-changed',
    MEASUREMENT_UNITS_CHANGED = 'measurement-units-changed',
    PAUSES_CHANGED = 'pauses-changed',
    PRODUCTION_ORDER_PROCESSES_CHANGED = 'production-order-processes-changed',
    PRODUCTION_ORDERS_CHANGED = 'production-orders-changed',
    PRODUCTION_PROCESSESS_CHANGED = 'production-processess-changed',
    PRODUCTION_ROUTES_CHANGED = 'production-routes-changed',
    SCRAP_CAUSES_CHANGED = 'scrap-causes-changed',
    SCRAP_REASONS_CHANGED = 'scrap-reasons-changed',
    SCREENS_CHANGED = 'screens-changed',
    SENSOR_DATA_CHANGED = 'sensor-data-changed',
    STATUSES_CHANGED = 'statuses-changed',
    USERS_CHANGED = 'users-changed',
}
