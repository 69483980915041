import { Injectable } from '@angular/core';
import { USER } from '../constants/storage';
import {
    PermissionDto,
    PermissionValuesDto,
} from 'src/app/shared/dto/permission.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ProfileDto } from 'src/app/shared/dto/profile.dto';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private _permissions: PermissionDto[] = [];

    constructor() {
        this.loadPermissions();
    }

    public getPermissionsByKey(key: string): PermissionValuesDto[] {
        let permission: PermissionDto | undefined;

        try {
            if (!key) {
                return [];
            }

            if (this._permissions.length === 0) {
                this.loadPermissions();
            }

            permission = this._permissions.find(
                (permission) => permission.key === key,
            );
        } catch (e: any) {
            throw new Error(`No se ha podido obtener los permisos por la key ${key}: Error: ${e.message}`);
        }

        return permission?.values ?? [];
    }

    public getPermissionByKeys(mainKey: string, permissionKey: string): PermissionValuesDto | undefined {
        try {
            if (this._permissions.length === 0 && (!mainKey || !permissionKey)) {
                return undefined;
            }

            return this.getPermissionsByKey(mainKey).find(
                (p) => p.key === permissionKey
            );
        } catch (e: any) {
            throw new Error(`No se ha podido obtener el permiso ${permissionKey} de ${mainKey}: Error: ${e.message}`);
        }
    }

    public hasPermission(permissionKeys: string[]): boolean {
        if (this._permissions.length === 0) {
            this.loadPermissions();
        }

        if (this._permissions) {
            permissionKeys.forEach((key): void => {
                const permission = this._permissions.find(
                    (permission) => permission.key === key
                );

                if (!permission || !permission.activated) {
                    return;
                }
            });

            return true;
        }

        return false;
    }

    public canRead(mainKey: string, permissionKey: string): boolean {
        try {
            if (this._permissions.length === 0 || !mainKey || !permissionKey) {
                return false;
            }

            return (this.getPermissionByKeys(mainKey, permissionKey)?.read ?? false);
        } catch (e: any) {
            throw new Error(`No se ha podido obtener el permiso de lectura de ${permissionKey} en ${mainKey}: Error: ${e.message}`);
        }
    }

    public canEdit(mainKey: string, permissionKey: string): boolean {
        try {
            if (this._permissions.length === 0 || !mainKey || !permissionKey) {
                return false;
            }

            return (this.getPermissionByKeys(mainKey, permissionKey)?.edit ?? false);
        } catch (e: any) {
            throw new Error(`No se ha podido obtener el permiso de edición de ${permissionKey} en ${mainKey}: Error: ${e.message}`);
        }
    }

    public canDelete(mainKey: string, permissionKey: string): boolean {
        try {
            if (this._permissions.length === 0 || !mainKey || !permissionKey) {
                return false;
            }

            return (
                this.getPermissionByKeys(mainKey, permissionKey)?.delete ??
                false
            );
        } catch (e: any) {
            throw new Error(`No se ha podido obtener el permiso de borrado de ${permissionKey} en ${mainKey}: Error: ${e.message}`);
        }
    }

    private loadPermissions(): void {
        const userStr = localStorage.getItem(USER);

        if (userStr) {
            const user = JSON.parse(userStr) as UserDto;

            if (user?.profile) {
                this._permissions = user.profile.permissions;
            } else {
                throw new Error('User profile not found');
            }

            return;
        }

        this._permissions = [];
    }
}
